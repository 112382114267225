import { render, staticRenderFns } from "./CustomAttributesFormFields.vue?vue&type=template&id=96737d32&scoped=true&"
import script from "./CustomAttributesFormFields.vue?vue&type=script&lang=js&"
export * from "./CustomAttributesFormFields.vue?vue&type=script&lang=js&"
import style0 from "./CustomAttributesFormFields.vue?vue&type=style&index=0&id=96737d32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96737d32",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./PaymentCompleted.vue?vue&type=template&id=5e907b98&scoped=true&"
import script from "./PaymentCompleted.vue?vue&type=script&lang=js&"
export * from "./PaymentCompleted.vue?vue&type=script&lang=js&"
import style0 from "./PaymentCompleted.vue?vue&type=style&index=0&id=5e907b98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e907b98",
  null
  
)

export default component.exports
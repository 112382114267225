import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import adminRoutes from './admin.js'
import accountRoutes from './account.js'
import authRoutes from './auth.js'
import pageRoutes from './pages.js'
import profileRoutes from './profile.js'
import paymentRoutes from './payment.js'
import errorRoutes from './error.js'
import { setPageTitle } from '../utils.js'

const routes = [
  ...adminRoutes,
  ...accountRoutes,
  ...authRoutes,
  ...pageRoutes,
  ...profileRoutes,
  ...paymentRoutes,
  ...errorRoutes,
  { path: '/:catchAll(.*)', redirect: '/stran-ne-obstaja' },
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
})
router.beforeEach(redirectOldPaths)
router.beforeEach(authGuard)
router.beforeEach(sidebarCloser)
router.afterEach(setPageMeta)

export default router

function redirectOldPaths (to, from, next) {
  if (to.hash && to.hash.startsWith('#/')) {
    const pathWithoutHash = to.hash.slice(2)
    return next({ path: pathWithoutHash })
  }

  next()
}

function authGuard (to, from, next) {
  const isUserLoggedId = store.getters.isLoggedIn
  const isUserAdmin = isUserLoggedId && store.getters.isAdmin === true
  const isUserAccountAdmin = isUserLoggedId && (store.getters.isAdmin === true || store.getters.isAccountAdmin === true)
  const isLoginRequired = to.meta.isLoginRequired === true || to.meta.isAdminRequired === true
  const isLoginProhibited = to.meta.isLoginRequired === false

  store.dispatch('closeAuthModal')

  if (isLoginProhibited && isUserLoggedId) {
    next({ name: 'account_recent_finalized_documents_path' })
  } else if (isLoginRequired && !isUserLoggedId) {
    next({ name: 'login_path' })
  } else if (to.meta.isAdminRequired === true && !isUserAdmin) {
    next({ name: 'root_path' })
  } else if (to.meta.isAccountAdminRequired === true && !isUserAccountAdmin) {
    next({ name: 'root_path' })
  } else if (to.meta.isHiddenIfLoggedIn === true && isUserLoggedId) {
    next({ name: 'smart_contracts_path' })
  } else {
    next()
  }
}

function sidebarCloser (to, from, next) {
  // floating sidebar on mobile should close on every navigation
  if (window.screen.width < 700) {
    store.dispatch('setIsFloatingSidebarVisible', false)
  }
  next()
}

function setPageMeta (to) {
  Vue.nextTick(() => {
    setPageTitle(to.meta.title)
  })
}

<template>
  <div class="page-container">
    <div class="edit-finalized-document__header">
      <div class="edit-finalized-document__header__title">
        <icon name="arrow-left" @click="goToFinalizedDocuments" />
        <h1 class="page-title">{{ title }}</h1>
      </div>
      <v-button :disabled="isLoading" @click="onDelete" outlined background-color="red">Izbriši</v-button>
    </div>
    <div class="page-subtitle-badge" v-if="finalizedDocument && !isBulkEditing">
      {{ finalizedDocument.name }} {{ finalizedDocument.description && ` - ${finalizedDocument.description}` }}
    </div>
    <finalized-document-form v-show="!isLoading" @submit="submitFinalizedDocument" @delete="onDelete" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import VButton from 'design-system/VButton.vue'
import FinalizedDocumentForm from './forms/FinalizedDocumentForm'
import prompt from 'design-system/components/prompt/prompt'
import Icon from 'design-system/icons/Icon.vue'

export default {
  props: {
    id: { type: [String, Number], required: true },
    accountId: { type: [String, Number], required: true },
  },
  components: {
    FinalizedDocumentForm,
    VButton,
    Icon,
  },
  created () {
    let finalizedDocumentIds = [this.id]
    if (typeof this.id === 'string' && this.id.includes(',')) {
      finalizedDocumentIds = this.id.split(',')
    }
    this.initialize({ accountId: this.accountId, finalizedDocumentIds })
  },
  beforeDestroy () {
    this.reset()
  },
  computed: {
    ...mapState(['isLoading']),
    ...mapState('accountStore/finalizedDocuments/forms', ['finalizedDocumentIds']),
    ...mapGetters(['accountSlug']),
    ...mapGetters('accountStore/finalizedDocuments/forms', ['finalizedDocument', 'isBulkEditing']),
    title () {
      return this.finalizedDocumentIds.length > 1 ? `Lastnosti dokumentov (${this.finalizedDocumentIds.length})` : 'Lastnosti dokumenta'
    },
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments/forms', ['initialize', 'reset', 'submitFinalizedDocument', 'deleteFinalizedDocument']),
    async onDelete () {
      const result = await prompt.okText('Izbriši').confirm('Res želite izbrisati dokument? Akcija bo nepovratno izbrisala dokument.')
      if (result) {
        this.deleteFinalizedDocument(this.id).then(() => {
          this.$router.push({
            name: 'account_finalized_documents_path',
            params: {
              accountId: this.accountId,
            },
          })
        })
      }
    },
    goToFinalizedDocuments () {
      this.$router.push({
        name: 'account_finalized_documents_path',
        params: {
          accountId: this.accountSlug,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../common';

.edit-finalized-document__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.edit-finalized-document__header__title {
  display: flex;
  align-items: center;

  & .page-title {
    margin-bottom: 0;
  }

  & .icon-wrapper {
    display: flex;
    align-items: center;
    margin: 20px 16px 0 0;
    cursor: pointer;
  }
}

</style>

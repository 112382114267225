<template>
  <div class="datepicker-container">
    <md-datepicker :value="internalValue" v-bind="$attrs" v-on="$listeners" class="v-datepicker" ref="mdDatepicker">
      <slot></slot>
    </md-datepicker>
    <div class="datepicker-mixed-label" v-if="isMixedValue">{{ MIXED_VALUES_LABEL }}</div>
  </div>
</template>

<script>
import { MIXED_VALUES_LABEL } from '@/utils'

export default {
  inheritAttrs: false,
  props: {
    value: { type: [Date, String], required: false },
  },
  data () {
    return {
      MIXED_VALUES_LABEL,
      internalValue: this.value,
    }
  },
  watch: {
    value: {
      handler (newValue) {
        this.internalValue = newValue
      },
    },
    internalValue: {
      handler (newInternalValue) {
        if (this.internalValue === newInternalValue) {
          return
        }
        this.$emit('input', this.getMappedValue(newInternalValue))
      },
    },
  },
  computed: {
    isMixedValue () {
      return this.internalValue === MIXED_VALUES_LABEL
    },
  },
  methods: {
    getMappedValue (value) {
      return value === MIXED_VALUES_LABEL ? null : value
    },
    focusDatepicker () {
      this.$refs.mdDatepicker.$el.click()
    },
  },
}
</script>

<style lang="scss" scoped>
.datepicker-container {
  position: relative;
}

.datepicker-mixed-label {
  position: absolute;
  top: 26px;
  left: 32px;
  width: 350px;
  padding: 4px;
  background-color: white;
  pointer-events: none;
}
</style>

<!-- We're using an unscoped style tag for datepicker because VMaterial DS teleports the datepicker root element to document body :/ -->
<style lang="scss">
@import "app/assets/stylesheets/colors";

$today-orange: #FF791F;

.md-datepicker-body {
  width: 360px;
}

.md-datepicker-day {
  position: relative;
}

.md-datepicker-days .md-datepicker-day-button {
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: $datepicker-light-gray;
  color: $datepicker-medium-gray;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

.md-datepicker-days .md-datepicker-day,
.md-datepicker-days .md-datepicker-empty {
  padding: 5px;
}

.md-datepicker-week span {
  width: 23px;
  height: 24px;
  color: $datepicker-gray;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.md-datepicker-dialog.md-theme-default .md-datepicker-body-footer > .md-button.md-primary {
  padding: 8px 16px;
  border-radius: 8px;
  background: $primary-blue;
  color: $white;
  font-size: 13px;
  text-transform: none;
}

.md-datepicker-dialog.md-theme-default {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 8px;
}

.md-datepicker-body-header .md-button {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: $datepicker-light-gray;
}

.md-datepicker-header .md-datepicker-dayname {
  display: inline;
}

.md-datepicker-header .md-datepicker-year-select,
.md-datepicker-header .md-datepicker-date-select {
  margin-right: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
}

.md-datepicker-header .md-datepicker-year-select {
  opacity: inherit;
}

.md-datepicker-week {
  margin: 8px 0;
}

.md-datepicker-dialog.md-theme-default .md-datepicker-header {
  display: none;
}

.md-datepicker-month .md-datepicker-month-trigger .md-button-content {
  color: $datepicker-dark-gray;
  font-size: 16px;
  text-decoration-line: underline;
  text-transform: none;
}

.md-datepicker-body-content
  .md-datepicker-panel.md-datepicker-month-selector
  .md-button.md-datepicker-year-trigger.md-theme-default
  .md-button-content {
  color: $datepicker-dark-gray;
  font-size: 16px;
  font-weight: 600;
}

.md-datepicker-month-button {
  margin: 12px 0;
  color: $datepicker-dark-gray;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
}

.md-datepicker-day-button.md-datepicker-today.md-datepicker-day-button.md-datepicker-today {
  border: 1px solid $primary-blue;
  color: $primary-blue;

  &::before {
    position: absolute;
    top: 5px;
    right: 8px;
    width: 0;
    height: 0;
    border-width: 5px;
    border-style: solid;
    border-color: transparent;
    border-top-color: $today-orange;
    border-right-color: $today-orange;
    border-top-right-radius: 4px;
    content: "";
  }
}

.md-datepicker-dialog.md-theme-default .md-datepicker-days .md-datepicker-day-button.md-datepicker-selected {
  /* stylelint-disable-next-line  */
  border: 3px solid rgba(4, 102, 200, 0.2);
  border-radius: 4px;
  background: $primary-blue;
}

.md-datepicker-dialog.md-theme-default
  .md-datepicker-body-content
  .md-datepicker-panel
  .md-datepicker-month-button:hover {
  border-radius: 4px;
  /* stylelint-disable-next-line  */
  background: rgba(4, 102, 200, 0.2);
  color: #0466c8;
}
</style>

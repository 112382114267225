<template>
  <div :class="{ 'entity-table--sticky-header': isHeaderSticky }">
    <div v-if="isHeaderPresent" class="header">
      <h1 class="title">{{ title }}</h1>
      <search v-if="isSearchPresent" class="search" :input-label="searchText" has-search-icon @change="onSearchChange" />
      <v-button v-if="isAddNewPresent" class="add-button" @click="$emit('add')"><icon name="plus" :width="20" :height="20" />{{ addText }}</v-button>
    </div>

    <v-table v-if="isLoaded" class="table">
      <md-table-row class="table-header">
        <md-table-head v-for="(column, index) in augmentedColumns" :key="column.id">
          <v-checkbox v-if="index === 0 && isSelectAllPresent" class="select-all-checkbox" v-model="selectAll" />
          <div :class="{ 'table-head--clickable': column.sortById != null }" @click="onHeaderItemClick(column)">
            {{ column.name }} <icon v-if="column.sortById != null" :name="getSortIconName(column)" :width="18" :height="18" />
          </div>
        </md-table-head>
        <md-table-head v-if="isActionsSlotPresent"><div></div></md-table-head>
      </md-table-row>

      <slot>
        <md-table-row
          v-for="item in items"
          :key="item.id"
          :class="{ 'table-row--clickable': areItemsClickable }"
          @click="onItemClick(item, $event)"
        >
          <md-table-cell v-for="column in columns" :key="`${item.id}-${column.id}`">{{ getItemColumn(item, column) }}</md-table-cell>
          <slot name="actions" :item="item" />
        </md-table-row>
      </slot>

      <tr class="table-empty" v-if="items.length === 0">
        <td :colspan="columns.length">
          {{ emptyText }}
        </td>
      </tr>
    </v-table>

    <pagination
      v-if="isPaginationPresent"
      :current-page="augmentedPagination.currentPage"
      :total-results="augmentedPagination.totalResults"
      :total-pages="augmentedPagination.totalPages"
      :per-page="augmentedPagination.perPage"
      :next="augmentedPagination.next"
      :prev="augmentedPagination.prev"
      @page-change="onPageChange"
    />
  </div>
</template>

<script>
import VCheckbox from 'design-system/VCheckbox.vue'
import VTable from 'design-system/VTable.vue'
import VButton from 'design-system/VButton.vue'
import Icon from 'design-system/icons/Icon.vue'
import Pagination from './_Pagination.vue'
import Search from './_Search.vue'

export default {
  components: {
    Icon,
    Pagination,
    Search,
    VCheckbox,
    VButton,
    VTable,
  },
  props: {
    columns: { type: Array, required: true },
    sortBy: { type: String, default: null },
    items: { type: Array, required: true },
    areItemsClickable: { type: Boolean, default: false },
    isPaginationPresent: { type: Boolean, default: true },
    isAddNewPresent: { type: Boolean, default: true },
    isSearchPresent: { type: Boolean, default: true },
    isSelectAllPresent: { type: Boolean, default: false },
    isHeaderSticky: { type: Boolean, default: false }, // NOTE: this only works on desktop for now. For mobile the overflow: visible would break the appearance.
    isLoaded: { type: Boolean, default: true },
    title: { type: String, default: 'Entitete' },
    searchText: { type: String, default: 'Iskanje' },
    emptyText: { type: String, default: 'Ni rezultatov.' },
    addText: { type: String, default: 'Dodaj' },
    pagination : { type: Object, required: false,
      default: () => ({
        currentPage: 1,
        totalPages: 1,
        totalResults: 0,
        perPage: 50,
      }),
    },
  },
  data () {
    return {
      allSelected: false,
    }
  },
  created: function () {
    if (!this.isPaginationPresent) {
      return
    }
    [
      'currentPage',
      'totalPages',
      'totalResults',
      'perPage',
    ].forEach(prop => {
      if (!Object.prototype.hasOwnProperty.call(this.pagination, prop) || !Number.isInteger(this.pagination[prop])) {
        throw new Error(`The pagination object should have the '${prop}' property and it should be an integer!`)
      }
    })
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    selectAll : {
      get () {
        return this.allSelected
      },
      set (value) {
        this.allSelected = value
        this.$emit('select-all', value)
      },
    },
    isHeaderPresent () {
      return this.isSearchPresent || this.isAddNewPresent
    },
    isFirstPage () {
      return this.page === 1
    },
    isLastPage () {
      return this.numberOfPages <= this.page
    },
    augmentedColumns () {
      if (!this.sortBy) {
        return this.columns
      }
      return this.columns.map(column => {
        let icon = null
        if (column.sortById != null && this.sortBy.replace('-', '') === column.id) {
          icon = this.sortBy.startsWith('-') ? 'arrow_downward' : 'arrow_upward'
        }
        return {
          ...column,
          icon: icon,
        }
      })
    },
    augmentedPagination () {
      return {
        ...this.pagination,
        next: this.isLastPage ? null : this.pagination.currentPage + 1,
        prev: this.isFirstPage ? null : this.pagination.currentPage - 1,
      }
    },
    isActionsSlotPresent () {
      return !!this.$scopedSlots.actions
    },
  },
  methods: {
    onSearchChange (ev) {
      this.$emit('search', ev)
    },
    getItemColumn (item, column) {
      const value = item[column.id]
      if (typeof value === 'boolean') {
        return value ? 'Da' : 'Ne'
      }

      const columnIdString = column.id.toString()
      const isDateColumn = columnIdString.endsWith('_at') || columnIdString.endsWith('At') || columnIdString.endsWith('_on') || columnIdString.endsWith('On')
      if (!isDateColumn) {
        return value
      }

      if (!value) {
        return '/'
      }

      const dateValue = new Date(value)
      return dateValue.toLocaleDateString('sl')
    },
    onItemClick (item, event) {
      if (!this.areItemsClickable) {
        return
      }
      if (event.ctrlKey || event.metaKey || event.altKey) {
        this.$emit('clickWithModifier', item)
      } else {
        this.$emit('click', item)
      }
    },
    onHeaderItemClick (column) {
      if (column.sortById == null) {
        return
      }
      this.$emit('column-click', column)
    },
    onPageChange (page) {
      if (page === this.page) {
        return
      }
      this.$emit('page-change', page)
    },
    getSortIconName (column) {
      if (this.sortBy === column.sortById) {
        return 'sort-ascending'
      } else if (this.sortBy === `-${column.sortById}`) {
        return 'sort-descending'
      } else {
        return 'sort-inactive'
      }
    },
    getTableHeaderElement () {
      return this.$el.querySelector('.table-header')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../common';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  @include respond-to(small) {
    flex-direction: column;
    align-items: flex-start;

    & .search {
      margin-right: 0;
      margin-left: 0;
    }
  }
}

.title {
  margin-bottom: 32px;
  color: $text-black;
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
}

.search {
  margin-right: 16px;
  margin-left: 32px;
}

.add-button {
  flex-shrink: 0;

  .icon-wrapper {
    position: relative;
    top: 2px;
    margin-right: 12px;
  }
}

.table {
  text-align: left;

  /deep/ .md-table-head-label {
    display: flex;
  }
}

.select-all {
  width: 40px;
}

.select-all-checkbox {
  width: 21px;
  position: relative;
  top: -16px;
  left: -6px;
}

.table-head-icon {
  .md-icon {
    width: 14px;
    min-width: 14px;
    height: 14px;
    min-height: 14px;
    font-size: 14px !important;
  }
}

.table-head--clickable {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.table-row--clickable {
  cursor: pointer;
}

.table-pagination {
  display: flex;
  justify-content: flex-end;
}

.entity-table--sticky-header {
  @include respond-to(medium) {
    .table-header {
      position: sticky;
      top: $logged_in_header_height;
      z-index: $z_pop;
    }

    // need to override the vue-material overflow auto and hidden for sticky header
    /deep/ .md-table.md-table {
      overflow-x: visible;
    }

    /deep/ .md-table .md-table-content {
      overflow-x: visible;
    }

    /deep/ .md-table table {
      overflow: visible;
    }
  }
}
</style>

<style lang="scss">
@import 'app/assets/stylesheets/colors';

.md-table-head {
  background-color: $secondary_color_opaque_hex;

  & .icon-wrapper {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
}

.table-empty {
  font-size: 16px;
  line-height: 56px;
  font-weight: 500;
  text-align: center;
}
</style>

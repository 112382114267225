import axios from 'axios'
import { camelKeys, snakeKeys } from 'js-convert-case'
import router from '@/router'
import { getResponseErrorMessage, getAllRecords } from '@/store/utils'
import { deleteFinalizedDocument, getFinalizedDocumentUrl } from '../utils.js'
import { mergeObjects, removeMixedValues } from '@/utils.js'

const state = {
  finalizedDocumentAccountId: null,
  finalizedDocument: null,
  finalizedDocumentIds: [],
  availableEnvelopes: [],
  availableCustomAttributeKeys: [],
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    finalizedDocumentId (state, getters) {
      if (getters.isBulkEditing) {
        return null
      } else {
        return state.finalizedDocumentIds[0] ?? null
      }
    },
    isCreatingNewFinalizedDocument (_, getters) {
      return getters.finalizedDocumentId == null && !getters.isBulkEditing
    },
    finalizedDocument (state) {
      if (!state.finalizedDocument) {
        return null
      }

      return {
        ...state.finalizedDocument,
        previewUrl: getFinalizedDocumentUrl(state.finalizedDocument.id, true),
        pdfDownloadUrl: getFinalizedDocumentUrl(state.finalizedDocument.id),
      }
    },
    isBulkEditing (state) {
      return state.finalizedDocumentIds.length > 1
    },
    putPayload (state, getters, _, rootGetters) {
      let finalizedDocument = state.finalizedDocument
      // TODO: hack, don't insert invalid values in the first place
      if (getters.isBulkEditing && finalizedDocument.envelopeId === null) {
        delete finalizedDocument.envelopeId
      }

      finalizedDocument = snakeKeys(state.finalizedDocument, { recursive: true, recursiveInArray: true })
      removeMixedValues(finalizedDocument)

      return {
        finalized_document: {
          account_id: state.finalizedDocumentAccountId ?? rootGetters.accountId,
          ...finalizedDocument,
        },
      }
    },
    postPayload (_, getters, rootState) {
      const payload = getters.putPayload
      payload.userId = rootState.user.id
      return payload
    },
  },
  actions: {
    async initialize ({ commit, dispatch }, { finalizedDocumentIds, envelopeId }) {
      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_FINALIZED_DOCUMENT_IDS', finalizedDocumentIds)

      try {
        await Promise.all([
          dispatch('fetchEnvelopes'),
          dispatch('fetchCustomAttributeKeys'),
        ])

        if (finalizedDocumentIds) {
          await dispatch('fetchFinalizedDocuments')
        } else {
          const finalizedDocument = {}
          if (envelopeId) {
            finalizedDocument.envelopeId = envelopeId
          }
          commit('SET_FINALIZED_DOCUMENT', [finalizedDocument])
        }
      } finally {
        commit('SET_IS_LOADING', false, { root: true })
      }
    },
    prefillFinalizedDocumentAttributesFromEnvelope ({ commit, state, rootGetters }, envelopeId) {
      return axios
        .get(`/finalized_documents?by_account_id=${rootGetters.accountId}&by_envelope_id=${envelopeId}&sort=-created_at&limit=1`)
        .then(finalizedDocumentsResponse => {
          const finalizedDocument = camelKeys(finalizedDocumentsResponse.data[0])
          if (finalizedDocument) {
            const updatedFinalizedDocument = {
              ...state.finalizedDocument,
              counterpartyName: finalizedDocument.counterpartyName,
              counterpartyRegistrationNumber: finalizedDocument.counterpartyRegistrationNumber,
            }
            commit('SET_FINALIZED_DOCUMENT', [updatedFinalizedDocument])
          }
        })
    },
    fetchFinalizedDocuments ({ commit, state }) {
      if (!state.finalizedDocumentIds) {
        throw new Error('finalizedDocumentIds should be set in state')
      }

      const promises = state.finalizedDocumentIds.map(finalizedDocumentId => {
        return axios
          .get(`/finalized_documents/${finalizedDocumentId}?expand=1`)
          .then(response => response.data)
      })

      return Promise.all(promises)
        .then(finalizedDocuments => commit('SET_FINALIZED_DOCUMENT', finalizedDocuments))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju dokumenta, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    fetchEnvelopes ({ commit, rootGetters }) {
      return getAllRecords(`/envelopes?expand=1&by_account_id=${rootGetters.accountId}`)
        .then(records => commit('SET_AVAILABLE_ENVELOPES', records))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju map, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    fetchCustomAttributeKeys ({ commit, rootGetters }) {
      return getAllRecords(`/custom_attribute_keys?by_account_id=${rootGetters.accountId}&expand=1`)
        .then(records => commit('SET_AVAILABLE_CUSTOM_ATTRIBUTE_KEYS', records))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri pridobivanju tipov oznak, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
    },
    updateFinalizedDocumentObject ({ commit, dispatch, state }, newFinalizedDocument) {
      const isEnvelopePresent = newFinalizedDocument.envelope && newFinalizedDocument.envelope.id
      const isEnvelopeIdDifferent = newFinalizedDocument.envelope.id !== state.finalizedDocument.envelopeId
      if (isEnvelopeIdDifferent) {
        newFinalizedDocument.envelopeId = newFinalizedDocument.envelope.id
      }
      if (isEnvelopePresent && isEnvelopeIdDifferent) {
        dispatch('prefillFinalizedDocumentAttributesFromEnvelope', newFinalizedDocument.envelope.id)
      }
      commit('UPDATE_FINALIZED_DOCUMENT_OBJECT', newFinalizedDocument)
    },
    submitFinalizedDocument ({ commit, dispatch, getters }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const promise = getters.isCreatingNewFinalizedDocument ? dispatch('createFinalizedDocument') : dispatch('updateFinalizedDocument')

      return promise
        .then(() => {
          const text = getters.isBulkEditing ? 'Dokumenti uspešno shranjeni.' : 'Dokument uspešno shranjen.'
          commit('SET_MESSAGE', { text, type: 'info' }, { root: true })
          router.push({ name: 'account_finalized_documents_path' })
        })
        .catch(error => {
          console.error(error)
          const parsedError = getResponseErrorMessage(error) ?? ''
          commit('SET_MESSAGE', { text: `Napaka pri shranjevanju dokumenta - ${parsedError}`, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    createFinalizedDocument ({ commit, getters }) {
      return axios
        .post('/finalized_documents', getters.postPayload)
        .then(response => commit('SET_FINALIZED_DOCUMENT_IDS', [response.data.id]))
    },
    updateFinalizedDocument ({ state, getters }) {
      if (!state.finalizedDocumentIds) {
        throw new Error('finalizedDocumentIds should be set in state')
      }

      if (getters.isBulkEditing) {
        return axios.put('/finalized_documents/bulk_update', {
          ...getters.putPayload,
          ids: state.finalizedDocumentIds,
        })
      } else {
        return axios.put(`/finalized_documents/${state.finalizedDocumentIds[0]}`, getters.putPayload)
      }
    },
    deleteFinalizedDocument ({ commit }, finalizedDocumentId) {
      return deleteFinalizedDocument(commit, finalizedDocumentId)
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
  },
  mutations: {
    SET_FINALIZED_DOCUMENT_IDS (state, finalizedDocumentIds) {
      state.finalizedDocumentIds = finalizedDocumentIds ?? []
    },
    SET_FINALIZED_DOCUMENT (state, finalizedDocuments) {
      const finalizedDocument = camelKeys(mergeObjects(finalizedDocuments), { recursive: true, recursiveInArray: true })
      // TODO: support custom attributes with bulk editing
      if (finalizedDocuments.length > 1) {
        finalizedDocument.customAttributes = []
      }
      state.finalizedDocument = finalizedDocument
      if (finalizedDocuments.length === 1 && finalizedDocuments[0].envelope && finalizedDocuments[0].envelope.account_id) {
        state.finalizedDocumentAccountId = finalizedDocuments[0].envelope.account_id
      }
    },
    UPDATE_FINALIZED_DOCUMENT_OBJECT (state, newFinalizedDocument) {
      if (newFinalizedDocument.envelope && newFinalizedDocument.envelope.id) {
        newFinalizedDocument.envelopeId = newFinalizedDocument.envelope.id
      }

      state.finalizedDocument = {
        ...state.finalizedDocument,
        ...newFinalizedDocument,
      }
    },
    SET_AVAILABLE_ENVELOPES (state, availableEnvelopes) {
      state.availableEnvelopes = availableEnvelopes.map(e => camelKeys(e, { recursive: true, recursiveInArray: true }))
    },
    SET_AVAILABLE_CUSTOM_ATTRIBUTE_KEYS (state, availableCustomAttributeKeys) {
      state.availableCustomAttributeKeys = availableCustomAttributeKeys.map(customAttributeKey => camelKeys(customAttributeKey, { recursive: true, recursiveInArray: true }))
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}

<template>
  <tr
    class="admin__table-row admin__table-row-expander"
    :class="selectedFinalizedDocumentClass"
  >
    <md-table-cell class="admin__table-row--name table-cell--no-padding">
      <div class="admin__table-row-icon admin__table-row-icon--expander" v-if="envelopeHasMultipleDocuments(envelope)" @click="envelopeClick(envelope)" @click.middle="envelopeClick(envelope)">
        <icon :class="`icon--${envelope.icon}`" :name="envelope.icon" />
      </div>
      <div class="admin__table-row-expander-name">
        <v-checkbox
          v-if="envelopeHasSingleDocument(envelope)"
          v-model="localSelectedFinalizedDocuments"
        />
        <span
          class="admin__table-row-expander-name--text"
          @click="envelopeClick(envelope)"
          @click.middle="envelopeClick(envelope)"
        >
          {{ truncatedName }}
        </span>
        <v-tooltip v-if="isNameTruncated" md-direction="top" md-delay="250">{{ envelope.name }}</v-tooltip>
      </div>
      <div class="admin__table-row-icon admin__table-row-hover-icon admin__table-row-icon--expander" v-if="envelopeHasMultipleDocuments(envelope) || envelopeHasNoDocuments(envelope)" @click="openEditEnvelope(envelope.id)">
        <v-icon>edit</v-icon>
        <v-tooltip md-direction="top">Uredi mapo</v-tooltip>
      </div>
      <div class="admin__table-row-icon admin__table-row-hover-icon admin__table-row-icon--expander" @click="addToEnvelope(envelope.id)">
        <v-icon>add</v-icon>
        <v-tooltip md-direction="top">Dodaj v mapo</v-tooltip>
      </div>
      <div v-if="envelopeHasNoDocuments(envelope)" class="admin__table-row-icon admin__table-row-hover-icon admin__table-row-icon--expander" @click="removeEnvelope(envelope.id)">
        <v-icon>delete</v-icon>
        <v-tooltip md-direction="top">Izbriši mapo</v-tooltip>
      </div>
    </md-table-cell>

    <template v-if="isFinalizedDocumentDataVisible(envelope)">
      <finalized-document-column
        v-for="column in envelopeColumns"
        :key="`${envelope.id}-${column.id}`"
        :finalized-documents="envelopeFinalizedDocuments(envelope)"
        :column="column"
        @status-click="onStatusClick"
      />
      <custom-attribute-column
        v-for="column in customAttributeColumns"
        :key="`${envelope.id}-${column.id}`"
        :finalized-document="envelopeFinalizedDocuments(envelope.finalizedDocuments)[0]"
        :column="column"
      />
    </template>
  </tr>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import FinalizedDocumentColumn from './FinalizedDocumentColumn'
import CustomAttributeColumn from './CustomAttributeColumn'
import FinalizedDocumentDisplayMixin from '../../shared/FinalizedDocumentDisplayMixin'
import VIcon from 'design-system/VIcon.vue'
import VTooltip from 'design-system/VTooltip.vue'
import VCheckbox from 'design-system/VCheckbox.vue'
import Icon from 'design-system/icons/Icon.vue'
import { truncateText } from '@/utils'
import prompt from 'design-system/components/prompt/prompt'
import { EXPAND_ENVELOPE_IN_LINE_MAX_SIZE } from '../../../../store/modules/account/finalized_documents'

export default {
  components: {
    FinalizedDocumentColumn,
    CustomAttributeColumn,
    VIcon,
    VTooltip,
    VCheckbox,
    Icon,
  },
  props: {
    envelope: { type: Object, required: true },
    customAttributeColumns: { type: Array, required: true },
    textFinalizedDocumentColumns: { type: Array, required: true },
    expandedEnvelopeIds: { type: Array, default: () => [] },
  },
  mixins: [FinalizedDocumentDisplayMixin],
  computed: {
    ...mapState('accountStore/finalizedDocuments', ['selectedFinalizedDocuments']),
    ...mapGetters(['accountSlug', 'getAccountIdForPath']),
    ...mapGetters('accountStore/finalizedDocuments', ['envelopes', 'isFilteringByEnvelope']),
    envelopeColumns () {
      return this.textFinalizedDocumentColumns.filter(column => column.id !== 'name')
    },
    localSelectedFinalizedDocuments: {
      get () {
        return !!this.selectedFinalizedDocuments.find(fd => fd.id === this.envelope.finalizedDocuments[0].id)
      },
      set (value) {
        this.toggleSelectedFinalizedDocument({ id: this.envelope.finalizedDocuments[0].id, value })
      },
    },
    selectedFinalizedDocumentClass () {
      if (this.envelope.finalizedDocuments.length === 1 && this.selectedFinalizedDocument && this.selectedFinalizedDocument.id === this.envelope.finalizedDocuments[0].id) {
        return 'finalized-document__row--selected'
      } else {
        return ''
      }
    },
    truncatedName () {
      return truncateText(this.envelope.name, 65)
    },
    isNameTruncated () {
      return this.envelope.name.length > 65
    },
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments', [
      'deleteFinalizedDocument',
      'toggleSelectedFinalizedDocument',
      'deleteEnvelope',
      'filterByEnvelope',
    ]),
    envelopeFinalizedDocuments (envelope) {
      // handle empty envelopes
      const finalizedDocuments = envelope.finalizedDocuments || []
      return finalizedDocuments.length > 0 ? envelope.finalizedDocuments : [{
        id: envelope.id,
        name: envelope.name,
        customAttributes: [],
      }]
    },
    isFinalizedDocumentDataVisible (envelope) {
      const isEnvelopeExpanded = this.expandedEnvelopeIds.includes(envelope.id)
      return !(isEnvelopeExpanded && this.envelopeHasMultipleDocuments(envelope))
    },
    envelopeHasMultipleDocuments (envelope) {
      return envelope.finalizedDocuments.length > 1 || this.isFilteringByEnvelope
    },
    envelopeHasNoDocuments (envelope) {
      return envelope.finalizedDocuments.length === 0
    },
    envelopeHasSingleDocument (envelope) {
      return envelope.finalizedDocuments.length === 1
    },
    envelopeClick (envelope) {
      if (this.isFilteringByEnvelope) {
        return
      }

      if (envelope.finalizedDocuments.length === 1) {
        this.onNameClick(envelope.finalizedDocuments[0])
      } else if (envelope.finalizedDocuments.length <= EXPAND_ENVELOPE_IN_LINE_MAX_SIZE) {
        this.toggleEnvelope(envelope.id)
      } else {
        this.filterByEnvelope(envelope)
      }
    },
    toggleEnvelope (envelopeId) {
      this.$emit('toggle-envelope', envelopeId)
    },
    openEditEnvelope (envelopeId) {
      this.$router.push({
        name: 'account_envelope_edit_path',
        params: {
          id: envelopeId,
          accountId: this.accountSlug,
        },
      })
    },
    addToEnvelope (envelopeId) {
      this.$router.push({
        name: 'account_finalized_document_create_path',
        params: {
          envelopeId: envelopeId,
          accountId: this.accountSlug,
        },
      })
    },
    async removeEnvelope (envelopeId) {
      const result = await prompt.okText('Izbriši').confirm('Res želite izbrisati mapo? Akcija bo nepovratno izbrisala mapo in vse dokumente v njej!')
      if (result) {
        this.deleteEnvelope(envelopeId)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';
@import '@/components/common';
@import '@/components/admin_common';
@import './finalized_document_display';

.table-cell--no-padding {
  /deep/ .md-table-cell-container {
    padding: 0;
  }
}
</style>

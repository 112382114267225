<template>
  <md-autocomplete
    :value="autocompleteProxyValue"
    v-bind="$attrs"
    v-on="$listeners"
    @md-closed="onBlur"
    @md-opened="onOpen"
    @md-changed="onChange"
    ref="autocomplete"
  >
    <slot></slot>
    <!-- pass through scoped slots -->
    <template v-for="(_, scopedSlotName) in $scopedSlots" #[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>
    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" #[slotName]>
      <slot :name="slotName" />
    </template>
  </md-autocomplete>
</template>

<script>
import { isString } from 'lodash-es'

export default {
  inheritAttrs: false,
  props: {
    value: { type: null, required: false },
  },
  data () {
    return {
      blurTriggerCount: 0, // for some reason blur is always triggered twice in the component when an option is selected
      autocompleteProxyValue: null,
      wasValueInputted: false,
    }
  },
  watch: {
    value: {
      handler (newValue) {
        this.autocompleteProxyValue = newValue
      },
      immediate: true,
    },
  },
  methods: {
    onBlur () {
      this.blurTriggerCount++
      setTimeout(() => {
        if (this.blurTriggerCount === 1 && this.wasValueInputted) {
          this.autocompleteProxyValue = ''
        }
      }, 250)
    },
    onOpen () {
      this.blurTriggerCount = 0
    },
    onChange (newInput) {
      if (isString(newInput)) {
        this.wasValueInputted = true
      }
    },
  },
}
</script>

<style lang="scss">
.md-menu-content .md-autocomplete-items {
  max-height: 65vh;
  overflow: auto;
}
</style>

<template>
  <md-table-cell
    :class="{ 'admin__table-row--name': column.id === 'name', 'admin__table-row--description': column.id === 'truncatedDescription', 'admin__table-row--status': column.id === 'status' }"
  >
    <v-checkbox v-if="column.id === 'name'" v-model="localSelectedFinalizedDocument" />
    <div class="u-h-100" :class="{ 'pointer': column.id === 'status' }" @click="onCellClick(column.id)">
      <div
        v-if="column.id === 'status' && showStatus"
        class="status-badge"
        :class="finalizedDocument.statusClass"
      >
        <icon v-if="finalizedDocument.statusIcon" :name="finalizedDocument.statusIcon" :width="14" :height="14" class="alert-icon" />
        {{ getColumnValue(finalizedDocument, column) }}
        <icon v-if="finalizedDocument.statusArrowIcon" name="arrow-right" :width="14" :height="14" class="arrow-icon" />
        <v-tooltip v-if="finalizedDocument.statusTooltip">{{ finalizedDocument.statusTooltip }}</v-tooltip>
      </div>
      <div
        v-else-if="column.id !== 'status'"
        class="u-inline-flex"
        @click.stop="column.id === 'name' && openPreviewFinalizedDocumentPdf(finalizedDocument)"
        @click.middle.stop="column.id === 'name' && openPreviewFinalizedDocumentPdf(finalizedDocument, true)"
      >
        {{ getColumnValue(finalizedDocument, column) }}
      </div>
      <v-tooltip
        v-if="column.id === 'truncatedDescription' && finalizedDocument.isDescriptionTruncated"
        md-direction="top"
        md-delay="250"
      >
        {{ finalizedDocument.description }}
      </v-tooltip>
    </div>
  </md-table-cell>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FinalizedDocumentDisplayMixin from '../../shared/FinalizedDocumentDisplayMixin'
import Icon from 'design-system/icons/Icon.vue'
import VTooltip from 'design-system/VTooltip.vue'
import VCheckbox from 'design-system/VCheckbox.vue'
import { truncateText } from '@/utils'

export default {
  components: {
    Icon,
    VTooltip,
    VCheckbox,
  },
  props: {
    finalizedDocuments: { type: Array, required: true },
    column: { type: Object, required: true },
  },
  mixins: [FinalizedDocumentDisplayMixin],
  computed: {
    ...mapState('accountStore/finalizedDocuments', ['selectedFinalizedDocuments']),
    localSelectedFinalizedDocument: {
      get () {
        return !!this.selectedFinalizedDocuments.find(fd => fd.id === this.finalizedDocument.id)
      },
      set (value) {
        this.toggleSelectedFinalizedDocument({ id: this.finalizedDocument.id, value })
      },
    },
    finalizedDocument () {
      // Choose the finalized document that expires the latest.
      const sortedFinalizedDocuments = this.finalizedDocuments
      sortedFinalizedDocuments.sort((a, b) => {
        if (!a.expiresOn && !b.expiresOn) {
          return new Date(b.signedOn) - new Date(a.signedOn)
        }

        if (!a.expiresOn) {
          return 1
        }

        if (!b.expiresOn) {
          return -1
        }

        if (a.expiresOn === b.expiresOn) {
          return new Date(b.signedOn) - new Date(a.signedOn)
        }

        return new Date(b.expiresOn) - new Date(a.expiresOn)
      })
      return this.getFinalizedDocumentWithStatus(sortedFinalizedDocuments[0])
    },
    showStatus () {
      return !!(this.finalizedDocument.pdfBlobHash || this.finalizedDocument.docxBlobHash)
    },
  },
  methods: {
    ...mapActions('accountStore/finalizedDocuments', ['toggleSelectedFinalizedDocument']),
    getColumnValue (item, column) {
      if (column.id === 'status') {
        return item.statusText
      }

      const value = item[column.id]
      if (value == null) {
        return '/'
      }
      const columnIdString = column.id.toString()
      const isDateColumn = (columnIdString.endsWith('At') || columnIdString.endsWith('On')) && !columnIdString.toLowerCase().includes('formatted')

      if (column.id === 'name') {
        return truncateText(value, 55)
      }

      return this.formatColumnValue(value, isDateColumn)
    },
    onCellClick (column) {
      if (column === 'status') {
        this.$emit('status-click', this.finalizedDocument)
      }
    },
  },
}
</script>

<style lang="scss">
@import 'app/assets/stylesheets/variables';

.pointer {
  cursor: pointer;
}

.status-badge {
  display: flex;
  justify-content: center;
  position: relative;
  width: 150px;
  padding: 6px 10px;
  border-radius: 100px;
  background-color: $silver-gray;
  color: $black;
  font-size: 15px;
  line-height: 17px;
  font-weight: 500;
  cursor: pointer;

  &.status--pay {
    color: white;
    background-color: $red;
  }

  &.status--signing {
    color: white;
    background-color: $status-signing;
  }

  &.status--signable {
    color: white;
    background-color: $primary-blue;
  }

  &.status--valid {
    background-color: $modrn-green;
  }

  &.status--warning {
    background-color: $status-expiring;
  }

  &.status--expired {
    color: white;
    background-color: $status-expired;
  }

  .arrow-icon {
    margin: 4px 0 0 8px;
  }

  .alert-icon {
    margin: 3px 8px 0 0;
  }
}

/deep/ .md-content {
  position: relative;
}
</style>

<template>
  <div v-if="isLoaded">
    <template v-for="customAttributeKey in customAttributeKeys">
      <v-datepicker
        v-if="customAttributeKey.customAttributeKeyType === 'date'"
        v-model="customAttributeDateValues[customAttributeKey.id]"
        :key="customAttributeKey.id"
        md-immediately
      >
        <label>{{ customAttributeKey.label }}</label>
      </v-datepicker>

      <v-autocomplete
        v-else-if="customAttributeKey.customAttributeKeyType === 'list'"
        :md-options="customAttributeKey.customAttributeListValues"
        :value="toListValueResult(customAttributesPerKey[customAttributeKey.id])"
        :key="customAttributeKey.id"
        @md-selected="setCustomAttributeValue(customAttributeKey, $event)"
        @md-opened="sizeProperly"
        md-dense
      >
        <label>{{ customAttributeKey.label }}</label>
        <template slot="md-autocomplete-item" slot-scope="{item}">{{ item.label }}</template>
      </v-autocomplete>

      <v-field v-else :key="customAttributeKey.id">
        <label>{{ customAttributeKey.label }}</label>
        <v-input v-model="customAttributesPerKey[customAttributeKey.id].value" @change="setCustomAttributeValue(customAttributeKey, $event)" />
      </v-field>
    </template>
  </div>
</template>

<script>
import { isEmpty } from 'lodash-es'
import AutocompleteFixMixin from '@/components/shared/AutocompleteFixMixin'
import VAutocomplete from 'design-system/VAutocomplete.vue'
import VDatepicker from 'design-system/VDatepicker.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: { VAutocomplete, VDatepicker, VField, VInput },
  data () {
    const customAttributeDateValues = {}
    this.customAttributeKeys.forEach(customAttributeKey => {
      customAttributeDateValues[customAttributeKey.id] = null
    })

    return {
      isLoaded: false,
      customAttributesPerKey: {},
      // we need to have a separate object so we can bind it to v-model of datepicker that writes a date object directly
      // we then transform the object into the correct form before emitting it out of the component
      customAttributeDateValues,
    }
  },
  props: {
    customAttributeKeys: { type: Array, required: true },
    customAttributes: { type: Array, required: true },
  },
  mixins: [AutocompleteFixMixin],
  methods: {
    setCustomAttributeValue (customAttributeKey, event) {
      if (event) {
        this.customAttributesPerKey[customAttributeKey.id].value = event.srcElement?._value ?? null
        this.customAttributesPerKey[customAttributeKey.id].customAttributeListValueId = event.id ?? null
      }
      this.customAttributesPerKey[customAttributeKey.id].customAttributeKeyId = customAttributeKey.id
      this.$emit('change', Object.values(this.customAttributesPerKey))
    },
    toListValueResult (customAttribute) {
      if (isEmpty(customAttribute)) {
        return null
      }

      const listItemName = this.customAttributeKeys.find(key => key.id === customAttribute.customAttributeKeyId).customAttributeListValues.find(lv => lv.id === customAttribute.customAttributeListValueId).label
      return {
        id: customAttribute.customAttributeListValueId,
        name: listItemName,
        toLowerCase: () => listItemName.toLowerCase(),
        toString: () => listItemName,
      }
    },
  },
  watch: {
    customAttributeKeys: {
      handler (newCustomAttributeKeys) {
        if (newCustomAttributeKeys.length < this.customAttributes.length) {
          return
        }

        this.customAttributeKeys.forEach(customAttributeKey => {
          let customAttribute = this.customAttributes.find(customAttribute => customAttribute.customAttributeKeyId === customAttributeKey.id)
          if (customAttribute == null) {
            customAttribute = {}
          }

          this.customAttributesPerKey[customAttributeKey.id] = customAttribute

          if (customAttributeKey.customAttributeKeyType === 'date') {
            this.customAttributeDateValues[customAttributeKey.id] = customAttribute.value ? new Date(customAttribute.value) : null
          }
        })
        this.isLoaded = true
      },
      immediate: true,
    },
    customAttributeDateValues: {
      handler (newCustomAttributeDateValues) {
        for (const [customAttributeKeyId, newValue] of Object.entries(newCustomAttributeDateValues)) {
          let newFormattedValue = newValue
          if (newValue && typeof newValue === 'object') {
            newFormattedValue = newValue.toISOString().split('T')[0]
          }

          this.customAttributesPerKey[customAttributeKeyId].value = newFormattedValue
          this.customAttributesPerKey[customAttributeKeyId].customAttributeKeyId = customAttributeKeyId
        }

        this.$emit('change', Object.values(this.customAttributesPerKey))
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';
</style>

import axios from 'axios'
import { snakeKeys } from 'js-convert-case'
import router from '@/router'
import { getResponseErrorMessage } from '@/store/utils'
import { getDefaultAccount } from '@/utils'

const state = {
}

const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {},
  actions: {
    login ({ commit, dispatch, rootState }, { email, password }) {
      dispatch('trackEvents', [
        {
          name: 'login',
          data: {
            send_to: 'G-5JHZXZD27X',
            method: 'Manual',
          },
        },
      ])

      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const payload = {
        user: {
          email,
          password,
          remember_me: 1,
        },
      }

      return axios
        .post('/users/sign_in', payload)
        .then(async function (response) {
          setNewCSRFToken(response.headers['x-csrf-token'])
          dispatch('setUser', response.data, { root: true })
          if (rootState.user.accounts.length > 0) {
            const defaultAccount = getDefaultAccount(rootState.user.accounts)
            if (defaultAccount && defaultAccount.id) {
              await dispatch('fetchAccount', defaultAccount.id)
            }
          }
          dispatch('closeAuthModal', null, { root: true })
          dispatch('redirectIfNeeded')
          dispatch('claimDocuments', null, { root: true })
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri prijavi, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => {
          commit('SET_IS_LOADING', false, { root: true })
        })
    },
    logout ({ commit, dispatch }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })

      return axios
        .delete('/users/sign_out')
        .then((response) => {
          setNewCSRFToken(response.headers['x-csrf-token'])
          dispatch('setUser', null, { root: true })
          router.push({ name: 'root_path' })
        })
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri odjavljanju, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    register ({ commit, dispatch }, user) {
      dispatch('trackEvents', [
        {
          name: 'conversion',
          data: {
            send_to: 'AW-16663620351/7BEUCJeLvsgZEP_V6ok-',
          },
        },
        {
          name: 'sign_up',
          data: {
            send_to: 'G-5JHZXZD27X',
            method: 'Manual',
          },
        },
      ], { root: true })

      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const signedUpFromPath = router.currentRoute.name === 'login_path' ? '/vzorci' : router.currentRoute.path
      user.signed_up_from_path = signedUpFromPath

      return axios
        .post('/users', { user: snakeKeys(user, { recursive: true, recursiveInArray: true }) })
        .then (() => true)
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri registraciji, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
          return false
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    initiatePasswordReset ({ commit, dispatch }, email) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const payload = {
        user: {
          email,
        },
      }

      return axios
        .post('/users/password', payload)
        .then(() => router.push({ name: 'password_reset_sent_path' }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri ponastavljanju gesla, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    resetPassword ({ commit, dispatch }, { token, password, confirmPassword }) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const payload = {
        user: {
          reset_password_token: token,
          password: password,
          password_confirmation: confirmPassword,
        },
      }

      return axios
        .put('/users/password', payload)
        .then(() => router.push({ name: 'login_path' }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri ponastavljanju gesla, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    reSendConfirmationEmail ({ commit, dispatch }, email) {
      commit('SET_IS_LOADING', true, { root: true })
      dispatch('clearMessage', null, { root: true })
      const payload = {
        user: {
          email,
        },
      }

      return axios
        .post('/users/confirmation', payload)
        .then(() => router.push({ name: 'registered_path' }))
        .catch(error => {
          console.error(error)
          const errorMessage = getResponseErrorMessage(error).replace(':', '') ?? 'Napaka pri pošiljanju potrditvenega sporočila, prosimo poskusite kasneje.'
          commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
        })
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    fetchAccount ({ commit }, accountId) {
      return axios.get(`/accounts/${accountId}`)
        .then(response => commit('SET_ACCOUNT', response.data, { root: true }))
    },
    redirectIfNeeded () {
      if (router.currentRoute.meta.isHiddenIfLoggedIn) {
        router.push({ name: 'smart_contracts_path' })
      }
    },
  },
}

function setNewCSRFToken (csrfToken) {
  document.getElementsByName('csrf-token')[0].setAttribute('content', csrfToken)
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
}

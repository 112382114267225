<template>
  <div class="auth__social">
    <form method="post" action="/users/auth/facebook" class="auth__social-form">
      <input type="hidden" class="auth__authenticity-token-input" name="authenticity_token" value="">
      <input type="hidden" name="redirect_path" :value="fullCurrentPath">
      <button type="submit" class="auth__social-button auth__social-button--facebook" @click="trackClick('Facebook')">
        <img class="auth__social-image auth__social-image--facebook" :src="require('app/assets/images/facebook.png')" alt="Facebook">
        <div class="auth__social-text">Facebook</div>
      </button>
    </form>

    <form method="post" action="/users/auth/google_oauth2" class="auth__social-form">
      <input type="hidden" class="auth__authenticity-token-input" name="authenticity_token" value="">
      <input type="hidden" name="redirect_path" :value="fullCurrentPath">
      <button class="auth__social-button auth__social-button--google" @click="trackClick('Google')">
        <img class="auth__social-image auth__social-image--google" :src="require('app/assets/images/google.svg')" alt="Google">
        <div class="auth__social-text">Google</div>
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  mounted () {
    this.$nextTick(() => {
      const csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')
      Array.from(document.getElementsByClassName('auth__authenticity-token-input')).forEach((element) => {
        element.value = csrfToken
      })
    })
  },
  computed: {
    fullCurrentPath () {
      if (this.$router.currentRoute.name === 'login_path') {
        return '/vzorci'
      } else {
        return this.$router.currentRoute.path
      }
    },
  },
  methods: {
    ...mapActions(['trackEvents']),
    trackClick (provider) {
      this.trackEvents([
        {
          name: 'login',
          data: {
            send_to: 'G-5JHZXZD27X',
            method: provider,
          },
        },
      ])
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

$facebook-blue: #1877F2;

.auth__social-button {
  display: flex;
  justify-content: center;
  position: relative;
  width: 216px;
  height: 48px;
  line-height: 32px;
  padding: 8px;
  margin: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #9FAEB2;
  cursor: pointer;
  color: $primary_color;
  background-color: transparent;
  text-align: center;

  @include respond-to(small) {
    width: 140px;
    margin: 8px 0;
  }

  &--facebook {
    color: white;
    border-color: $facebook-blue;
    background-color: $facebook-blue;

    @include respond-to(small) {
      margin-right: 8px;
    }

    .auth__social-text {
      color: white;
    }
  }
}

.auth__social-image {
  object-fit: contain;
  position: relative;
  top: 6px;
  margin-right: 16px;

  &--facebook {
    top: 4px;
    width: 22px;
  }

  @include respond-to(small) {
    margin-right: 8px;
  }
}

.auth__social-text {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
}
</style>

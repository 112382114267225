<template>
  <div>
    <div>
      Pametni vzorec ste uspešno poslali v podpis na sledeče email naslove: <br /><br />
      <ul>
        <li class="signer" v-for="signer in augmentedSigners" :key="signer.email">
          <icon class="signer-icon" :name="signer.isSigned ? 'checkmark-circle-green' : 'close'" />
          &nbsp;
          {{ signer.email }}
          <v-tooltip>{{ getSignerTooltip(signer) }}</v-tooltip>
        </li>
      </ul>
      <br />
      Ko vsi prejemniki podpišejo dokument, boste o tem prejeli obvestilo, dokument pa bo naložen v <a class="e-signature-link" @click.prevent="$emit('go-to-storage')">bazo dokumentov</a>.
    </div>
    <div class="e-signature-button">
      <v-button size="medium" @click="$emit('go-to-storage')">Nazaj v hrambo</v-button>
      <v-button size="medium" @click="$emit('re-sign')">Pošlji v podpis drugim osebam</v-button>
    </div>
  </div>
</template>

<script>
import Icon from 'design-system/icons/Icon.vue'
import VButton from 'design-system/VButton.vue'
import VTooltip from 'design-system/VTooltip.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    VButton,
    VTooltip,
  },
  props: {
    signers: { type: Array, required: true },
    signedBy: { type: Array, default: null },
  },
  computed: {
    ...mapGetters(['accountSlug']),
    augmentedSigners () {
      const signedByEmails = this.signedBy ? this.signedBy.map(s => s.email) : []
      return this.signers.map(signer => {
        return {
          ...signer,
          isSigned: signedByEmails.includes(signer.email),
        }
      })
    },
  },
  methods: {
    getSignerTooltip (signer) {
      return signer.isSigned ? 'Že podpisano' : 'Še ni podpisano'
    },
  },
}
</script>

<style scoped lang="scss">

.e-signature-button {
  margin-top: 40px;
}

.signer-icon {
  margin-top: 4px;
}

.e-signature-link {
  cursor: pointer;
  text-decoration: underline;
}

.signer {
  display: flex;
  align-items: center;
  width: fit-content;
}
</style>

<template>
  <div class="smart-contracts page-container" :class="{ 'page-container--with-sidebar': isSidebarPresent }">
    <div class="smart-contracts__heading">
      <h1 class="smart-contracts__heading__title">Vzorci</h1>
      <v-field class="smart-contracts__search">
        <icon class="smart-contracts__search-icon" name="search" /><label class="smart-contracts__search-label">{{ searchLabel }}</label>
        <v-input v-model="searchQuery"></v-input>
      </v-field>
      <div class="smart-contracts__view-toggle">
        <icon
          name="grid"
          class="smart-contracts__view-toggle__button"
          :class="{ 'smart-contracts__view-toggle__button--active': !isListView }"
          @click="setGridView"
        />
        <icon
          name="list"
          class="smart-contracts__view-toggle__button"
          :class="{ 'smart-contracts__view-toggle__button--active': isListView }"
          @click="setListView"
        />
      </div>
    </div>

    <div class="smart-contracts__top-searches">
      <div class="smart-contracts__top-searches__title">Najbolj iskano</div>
      <div
        class="smart-contracts__top-searches__tag"
        v-for="search in topSearches"
        :key="search.label"
        @click="handleSearch(search.searchQuery)"
      >
        {{ search.label }}
      </div>
    </div>
    <hr />

    <template v-if="documents.length > 0">
      <smart-contracts-list-view
        v-if="isListView"
        :documents="documents"
        @document-click="openDocumentComposition"
      />
      <smart-contracts-card-view
        v-else
        :documents="documents"
        @document-click="openDocumentComposition"
      />
      <div class="smart-contracts__load-more" v-if="!isLastPage">
        <v-button @click="fetchMoreDocuments" :disabled="isLoading">Prikaži več</v-button>
      </div>
    </template>

    <div class="smart-contracts__empty" v-if="documents.length === 0 && !isLoading">
      Noben vzorec se ne ujema z iskanjem
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapActions, mapGetters, mapState } from 'vuex'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'
import VButton from 'design-system/VButton.vue'
import Icon from 'design-system/icons/Icon.vue'
import SmartContractsCardView from './SmartContractsCardView.vue'
import SmartContractsListView from './SmartContractsListView.vue'

export default {
  components: {
    VField,
    VInput,
    VButton,
    Icon,
    SmartContractsCardView,
    SmartContractsListView,
  },
  created () {
    this.initialize().then(() => {
      this.track()
    })
  },
  data () {
    return {
      isListView: false,
      isInitialLoad: true,
      topSearches: [
        { label: 'Najemna pogodba', searchQuery: 'najemna pogodba' },
        { label: 'Pogodba o poslovnem sodelovanju', searchQuery: 'pogodba o poslovnem sodelovanju' },
        { label: 'Pogodba o zaposlitvi', searchQuery: 'pogodba o zaposlitvi' },
        { label: 'Aneks', searchQuery: 'aneks' },
        { label: 'Pravilnik', searchQuery: 'pravilnik' },
        { label: 'Sporazum', searchQuery: 'sporazum' },
        { label: 'Splošni pogoji', searchQuery: 'splošni pogoji' },
      ],
    }
  },
  watch: {
    account: {
      handler () {
        this.initialize()
      },
    },
    isLoading: {
      handler (newValue) {
        if (this.isInitialLoad && newValue === false) {
          this.isInitialLoad = false
          window.scrollTo(0, 0)
        }
      },
    },
    query: {
      handler () {
        window.scrollTo(0, 0)
      },
    },
  },
  computed: {
    ...mapState(['isLoading', 'account', 'user']),
    ...mapState('pages', ['query']),
    ...mapGetters('pages', ['documents', 'isLastPage']),
    searchQuery: {
      get () {
        return this.query
      },
      set (newQuery) {
        if (newQuery === this.query) {
          return
        }
        this.debounceSetQuery(newQuery)
      },
    },
    searchLabel () {
      const isSmall = window.innerWidth < 1000
      return this.isMobile || isSmall ? 'Išči po vzorcih' : 'Išči po vzorcih (najemna pogodba, avtorska pogodba ...)'
    },
  },
  methods: {
    ...mapActions(['trackEvents']),
    ...mapActions('pages', ['initialize', 'setQuery', 'fetchMoreDocuments']),
    track () {
      const documentsItems = []
      this.documents.forEach((document, index) => {
        documentsItems.push({
          item_category: 'Vsi vzorci',
          item_list_id: 'vzorci',
          item_list_name: 'Vzorci',
          item_id: document.id,
          item_name: document.name,
          price: document.price,
          quantity: 1,
          index: index + 1,
        })
      })
      this.trackEvents([
        {
          name: 'view_item_list',
          data: {
            send_to: 'AW-16663620351',
            user_id: this.user?.id,
            dynx_pagetype: 'other',
            dynx_itemid: this.documents.map((document) => document.id),
            currency: 'EUR',
            items: this.documents.map(document => ({
              'id': document.id,
              'price': document.price,
              'google_business_vertical': 'custom',
            })),
          },
        },
        {
          name: 'view_item_list',
          data: {
            send_to: 'G-5JHZXZD27X',
            items: documentsItems,
          },
        },
      ])
    },
    openDocumentComposition ({ document, event }) {
      const openInNewTab = event.ctrlKey || event.metaKey
      const newTabArgument = openInNewTab ? '_blank' : '_self'
      const routeData = this.$router.resolve({
        name: 'compose_document_path',
        params: {
          id: document.slug,
        },
      })
      window.open(routeData.href, newTabArgument)
    },
    setListView () {
      this.isListView = true
    },
    setGridView () {
      this.isListView = false
    },
    handleSearch (searchQuery) {
      this.setQuery(searchQuery)
    },
    debounceSetQuery: debounce(function (query) {
      this.setQuery(query)
    }, 250),
  },
}
</script>

<style scoped lang="scss">
@import "./common";
@import "../common";

.smart-contracts.page-content {
  background-color: $white;
}

.smart-contracts hr {
  border: 0;
  border-bottom: 1px solid $input-light;
}

.smart-contracts__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respond-to(small) {
    display: block;
  }

  & .smart-contracts__heading__title {
    margin-right: 52px;
    color: $black;
    font-size: 40px;
    font-weight: 600;

    @include respond-to(small) {
      margin-bottom: 16px;
    }
  }

  @include respond-to(small) {
    padding: 16px 0;
  }
}

// TODO: Unify with EntityTable.vue
.smart-contracts__search {
  .md-input {
    padding-left: 58px;
    border: 1px solid $input-light;
    background: $white;
  }

  & .icon-wrapper {
    position: absolute;
    top: 28px;
    left: 22px;
  }

  & label:not(.md-focused) {
   margin-left: 44px;
  }

  &.md-has-value, &.md-focused {
    & label {
      margin-left: 0;
    }
  }
}

.smart-contracts__empty {
  padding: 16px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.smart-contracts__view-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $input-light;
  border-radius: 8px;
  margin-left: 33px;

  @include respond-to(small) {
    margin: 0 0 16px 0;
  }

  &__button {
    display: flex;
    width: 46px;
    height: 56px;
    align-items: center;
    justify-content: center;
    background: $light-gray;
    color: $icon-color;
    cursor: pointer;
    border-radius: 8px;

    &--active {
      color: $black;
    }
  }
}

.smart-contracts__top-searches {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__title {
    margin-right: 16px;
    // stylelint-disable-next-line
    color: rgba(20, 19, 22, 0.5);
    font-size: 14px;
  }

  &__tag {
    padding: 8px 12px;
    border-radius: 6px;
    margin: 8px;
    background: $azureish-white;
    color: $modrn-blue-dark;
    cursor: pointer;
    font-size: 14px;
  }
}

.smart-contracts__search-label {
  height: 23px;
  overflow: hidden;
}

.smart-contracts__load-more {
  width: 100%;
  margin-top: 32px;
  text-align: center;
}
</style>

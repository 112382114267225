<template>
  <div class="finalized-documents__actions">
    <v-menu class="download-menu">
      <div>
        <v-button :disabled="actionsState.download.disabled" size="small" outlined md-menu-trigger @click="downloadClick">
          <icon name="download" :width="16" :height="16" />
          {{ actionsState.download.label }}
        </v-button>
        <v-tooltip v-show="!!actionsState.download.tooltip">{{ actionsState.download.tooltip }}</v-tooltip>
      </div>
      <md-menu-content v-if="!actionsState.downloadDocx.disabled" class="download-menu__dropdown">
        <div class="download-menu__dropdown-item" @click="$emit('downloadPdf')">
          <icon name="file-pdf" />{{ actionsState.downloadPdf.label }}
        </div>
        <div class="download-menu__dropdown-item" @click="$emit('downloadDocx')">
          <icon name="file-text" />{{ actionsState.downloadDocx.label }}
        </div>
      </md-menu-content>
    </v-menu>
    <div>
      <v-button :disabled="actionsState.edit.disabled" size="small" outlined @click="$emit('edit')">
        <icon name="edit" :width="16" :height="16" />
        {{ actionsState.edit.label }}
      </v-button>
      <v-tooltip v-show="!!actionsState.edit.tooltip">{{ actionsState.edit.tooltip }}</v-tooltip>
    </div>
    <div>
      <v-button :disabled="actionsState.sign.disabled" size="small" outlined @click="$emit('sign')">
        <icon name="signature" :width="16" :height="16" />
        {{ actionsState.sign.label }}
      </v-button>
      <v-tooltip v-show="!!actionsState.sign.tooltip">{{ actionsState.sign.tooltip }}</v-tooltip>
    </div>
    <div>
      <v-button :disabled="actionsState.delete.disabled" background-color="red" size="small" outlined @click="$emit('remove')">
        <icon name="trash" :width="16" :height="16" />
        {{ actionsState.delete.label }}
      </v-button>
      <v-tooltip v-show="!!actionsState.delete.tooltip">{{ actionsState.delete.tooltip }}</v-tooltip>
    </div>
  </div>
</template>

<script>
import VButton from 'design-system/VButton.vue'
import VMenu from 'design-system/VMenu.vue'
import VTooltip from 'design-system/VTooltip.vue'
import Icon from 'design-system/icons/Icon.vue'

export default {
  props: {
    actionsState: { type: Object, required: true },
  },
  components: {
    VButton,
    VMenu,
    VTooltip,
    Icon,
  },
  methods: {
    downloadClick () {
      if (this.actionsState.downloadDocx.disabled) {
        this.$emit('downloadPdf')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/variables';

.finalized-documents__actions {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 0 20px;

  > * {
    margin-top: 8px;
  }

  > *:not(:last-child) {
    margin-right: 16px;
  }

  & button {
  & .icon-wrapper {
    margin-right: 8px;
  }

  // otherwise icons are cut off
   /deep/ & .md-ripple {
      overflow: visible;
      mask-image: none;
    }
  }
}

.download-menu__dropdown {
  & /deep/ .md-list {
    border: none;
    border-radius: 8px;
    // stylelint-disable-next-line
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }

  & .download-menu__dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 14px;
    margin: 8px;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    color: $primary_color;
    cursor: pointer;

    & .icon-wrapper {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

    &:hover {
      border-radius: 8px;
      background-color: #F1F2F7;
      color: $primary-blue;
    }
  }
}
</style>

<template>
  <div class="r-tag" :class="classes">
    <slot></slot>
    <icon name="close" v-if="closable" class="r-tag-close-icon" :width="28" :height="28" @click="close" />
  </div>
</template>

<script>
import Icon from 'design-system/icons/Icon.vue'

export default {
  props: {
    backgroundColor: {
      type: String,
      default: 'primary-blue',
      validator: (value) => ['primary-blue', 'white', 'black', 'yellow', 'modrn-blue-50'].includes(value),
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close (event) {
      this.$emit('close', event)
    },
  },
  computed: {
    classes () {
      const classes = []
      switch (this.backgroundColor) {
      case 'white':
        classes.push('r-tag-white')
        break
      case 'black':
        classes.push('r-tag-black')
        break
      case 'yellow':
        classes.push('r-tag-yellow')
        break
      case 'modrn-blue-50':
        classes.push('r-tag-modrn-blue-50')
        break
      default:
        classes.push('r-tag-primary-blue')
        break
      }
      return classes.join(' ')
    },
  },
  components: { Icon },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/colors";

.r-tag {
  display: inline-flex;
  height: 32px;
  align-items: center;
  padding: 0 16px;
  border-radius: 200px;
  background: $primary-blue;
  color: $white;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;

  &.r-tag-white {
    border: 1px solid $tag-border-gray;
    background: $white;
    color: $primary-blue;
  }

  &.r-tag-black {
    background: $black;
    color: $white;
  }

  &.r-tag-yellow {
    background: $tag-yellow;
    color: $tag-black;
  }

  &.r-tag-modrn-blue-50 {
    background: $modrn-blue-50;
    color: $primary-blue;
  }

  .r-tag-close-icon {
    position: relative;
    left: 6px;
    margin-top: 3px;
    cursor: pointer;
  }
}
</style>

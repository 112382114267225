import axios from 'axios'
import { getResponseErrorMessage } from '@/store/utils'

export const deleteFinalizedDocument = function (commit, finalizedDocumentId) {
  commit('SET_IS_LOADING', true, { root: true })
  commit('SET_MESSAGE', null, { root: true })

  return axios
    .delete(`/finalized_documents/${finalizedDocumentId}`)
    .catch(error => {
      console.error(error)
      const errorMessage = getResponseErrorMessage(error) ?? 'Napaka pri brisanju dokumenta, prosimo poskusite kasneje.'
      commit('SET_MESSAGE', { text: errorMessage, type: 'error' }, { root: true })
    })
    .finally(() => commit('SET_IS_LOADING', false, { root: true }))
}

export const getFinalizedDocumentUrl = function (finalizedDocumentId, isPreview = false, format = 'pdf') {
  let url = `/finalized_documents/${finalizedDocumentId}/download?format=${format}`
  if (isPreview) {
    url += '&disposition=inline'
  }

  return url
}

import { render, staticRenderFns } from "./RegisterUserForm.vue?vue&type=template&id=197a9abc&scoped=true&"
import script from "./RegisterUserForm.vue?vue&type=script&lang=js&"
export * from "./RegisterUserForm.vue?vue&type=script&lang=js&"
import style0 from "./RegisterUserForm.vue?vue&type=style&index=0&id=197a9abc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "197a9abc",
  null
  
)

export default component.exports
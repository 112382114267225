<template>
  <div>
    <label class="radio-label">Pametne variable</label>
    <v-button class="smart-variables-clear-button md-icon-button" @click="clearSmartFields">
      <v-icon>close</v-icon>
      <v-tooltip md-direction="top" md-delay="250">Počisti nastavitve pametne variable.</v-tooltip>
    </v-button>
    <div>
      <v-radio v-model="form.smartFieldType" value="isCompanyVatId">Davčna številka firme</v-radio>
      <v-radio v-model="form.smartFieldType" value="isCompanyName">Ime firme</v-radio>
      <v-radio v-model="form.smartFieldType" value="isCompanyAddress">Naslov firme</v-radio>
      <v-radio v-model="form.smartFieldType" value="isCompanyPostAndPostCode">Pošta firme</v-radio>
      <v-radio v-model="form.smartFieldType" value="isPersonName">Ime Osebe</v-radio>
      <v-radio v-model="form.smartFieldType" value="isPersonAddress">Naslov Osebe</v-radio>
      <v-radio v-model="form.smartFieldType" value="isPersonPostAndPostCode">Pošta Osebe</v-radio>
    </div>

    <v-field :class="getValidationClass('name')">
      <label>Vprašanje uporabniku</label>
      <v-input v-model="form.name"></v-input>
      <span class="md-error" v-if="!$v.form.name.required">Vpišite vprašanje</span>
    </v-field>

    <v-field :class="getValidationClass('info')">
      <label>Dodatne informacije (za uporabnika)</label>
      <v-input v-model="form.info"></v-input>
    </v-field>

    <v-field>
      <label>Privzeta vrednost (opcijsko)</label>
      <v-input v-model="form.defaultValue"></v-input>
    </v-field>

    <v-field :class="getValidationClass('pattern')">
      <label>Vzorec</label>
      <v-input v-model="form.pattern"></v-input>
      <span class="md-error" v-if="!$v.form.pattern.required">Vpišite vzorec</span>
      <span class="md-error" v-else-if="!$v.form.pattern.isPattern">Vzorec se mora začeti z '$/' in končati z '/'</span>
    </v-field>

    <v-field :class="getValidationClass('order')">
      <label>Vrstni red variable v sestavku</label>
      <v-input v-model="form.order" type="number"></v-input>
      <span class="md-error" v-if="!$v.form.order.required">Vpišite vrstni red</span>
    </v-field>

    <label class="radio-label">Tip variable</label>
    <div class="md-field" :class="getValidationClass('variableType')">
      <v-radio @change="initializeVariableOptions" v-model="form.variableType" value="string">Tekst</v-radio>
      <v-radio @change="initializeVariableOptions" v-model="form.variableType" value="text">Daljši Tekst</v-radio>
      <v-radio @change="initializeVariableOptions" v-model="form.variableType" value="integer">Cela številka</v-radio>
      <v-radio @change="initializeVariableOptions" v-model="form.variableType" value="float">Decimalna številka</v-radio>
      <v-radio @change="initializeVariableOptions" v-model="form.variableType" value="date">Datum</v-radio>
      <v-radio @change="initializeVariableOptions" v-model="form.variableType" value="option">Izbira</v-radio>
      <br />
      <div class="md-error" v-if="!$v.form.variableType.required">Izberite tip variable</div>
    </div>

    <div class="nested-form" v-if="form.variableType === 'option'">
      <div v-for="(variableOption, index) in form.variableOptions" :key="variableOption.frontendId" :class="getNestedFormClass(index)">
        <br />
        <div :style="{ display: 'flex', 'justify-content': 'space-between' }">
          <div class="md-subheading">opcija {{ index + 1 }}</div>
          <v-button class="md-icon-button" v-if="form.variableOptions.length > 2" @click="removeVariableOption(index)"><v-icon>close</v-icon></v-button>
        </div>
        <variable-option-form-fields ref="variableOptions" :variable-option="variableOption" @change="onVariableOptionChange(index, $event)" />
      </div>
      <v-button size="medium" class="regular-button" @click="addVariableOption">DODAJ OPCIJO VARIABLE</v-button>
      <md-divider />
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VRadio from 'design-system/VRadio.vue'
import ValidationMixin from '../../../shared/ValidationMixin'
import NestedFormMixin from '../../NestedFormMixin'
import VariableOptionFormFields from './VariableOptionFormFields'
import VButton from 'design-system/VButton.vue'
import VField from 'design-system/VField.vue'
import VIcon from 'design-system/VIcon.vue'
import VTooltip from 'design-system/VTooltip.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: {
    VariableOptionFormFields,
    VRadio,
    VButton,
    VField,
    VIcon,
    VTooltip,
    VInput,
  },
  props: {
    variable: { type: Object, required: false },
  },
  data () {
    return {
      formRaw: {
        name: null,
        info: null,
        defaultValue: null,
        variableType: null,
        pattern: null,
        order: null,
        variableOptions: [],
        smartFieldType: null,
      },
    }
  },
  mixins: [ValidationMixin, NestedFormMixin],
  created () {
    if (!this.variable) {
      return
    }

    this.formRaw.name = this.variable.name
    this.formRaw.info = this.variable.info
    this.formRaw.defaultValue = this.variable.defaultValue
    this.formRaw.variableType = this.variable.variableType
    this.formRaw.pattern = this.variable.pattern
    this.formRaw.order = this.variable.order

    if (this.variable.isCompanyVatId) {
      this.formRaw.smartFieldType = 'isCompanyVatId'
    }
    if (this.variable.isCompanyName) {
      this.formRaw.smartFieldType = 'isCompanyName'
    }
    if (this.variable.isCompanyAddress) {
      this.formRaw.smartFieldType = 'isCompanyAddress'
    }
    if (this.variable.isCompanyPostAndPostCode) {
      this.formRaw.smartFieldType = 'isCompanyPostAndPostCode'
    }
    if (this.variable.isPersonName) {
      this.formRaw.smartFieldType = 'isPersonName'
    }
    if (this.variable.isPersonAddress) {
      this.formRaw.smartFieldType = 'isPersonAddress'
    }
    if (this.variable.isPersonPostAndPostCode) {
      this.formRaw.smartFieldType = 'isPersonPostAndPostCode'
    }

    if (this.variable.variableOptions) {
      const variableOptionsWithFrontendIds = createVariableOptionsFrontendIds(this.variable.variableOptions)
      this.formRaw.variableOptions = variableOptionsWithFrontendIds
    }

    function createVariableOptionsFrontendIds (variableOption) {
      const variablesOptionsWithFrontendIds = []
      variableOption.forEach(variableOption => {
        variablesOptionsWithFrontendIds.push({
          ...variableOption,
          frontendId: Math.random().toString(36),
        })
      })

      return variablesOptionsWithFrontendIds
    }
  },
  watch: {
    form: {
      handler (newFormValue) {
        this.$emit('change', {
          ...this.variable,
          ...newFormValue,
        })
      },
      deep: true,
    },
  },
  computed: {
    form () {
      const form = this.formRaw
      form.isCompanyVatId = this.formRaw.smartFieldType === 'isCompanyVatId'
      form.isCompanyName = this.formRaw.smartFieldType === 'isCompanyName'
      form.isCompanyAddress = this.formRaw.smartFieldType === 'isCompanyAddress'
      form.isCompanyPostAndPostCode = this.formRaw.smartFieldType === 'isCompanyPostAndPostCode'
      form.isPersonName = this.formRaw.smartFieldType === 'isPersonName'
      form.isPersonAddress = this.formRaw.smartFieldType === 'isPersonAddress'
      form.isPersonPostAndPostCode = this.formRaw.smartFieldType === 'isPersonPostAndPostCode'

      return form
    },
  },
  methods: {
    onVariableOptionChange (index, $event) {
      this.formRaw.variableOptions[index] = {
        ...this.formRaw.variableOptions[index],
        ...$event,
      }
    },
    initializeVariableOptions ($ev) {
      if ($ev === 'option') {
        this.formRaw.variableOptions = [
          { frontendId: Math.random().toString(36) },
          { frontendId: Math.random().toString(36) },
        ]
      } else {
        this.formRaw.variableOptions = []
      }
    },
    addVariableOption () {
      this.formRaw.variableOptions.push({ frontendId: Math.random().toString(36) })
    },
    removeVariableOption (index) {
      this.formRaw.variableOptions.splice(index, 1)
    },
    clearSmartFields () {
      this.formRaw.smartFieldType = null
    },
    runAllValidations () {
      this.$v.$touch()
      const variableOptionRefs = this.$refs.variableOptions
      if (!variableOptionRefs) {
        return
      }

      variableOptionRefs.forEach(variableOptionRef => variableOptionRef.runAllValidations())
    },
    isValid () {
      if (this.$v.$invalid) {
        return false
      }

      const variableOptionRefs = this.$refs.variableOptions
      if (!variableOptionRefs) {
        return true
      }

      return variableOptionRefs.reduce((areVariableOptionRefsValid, variableOptionRef) => areVariableOptionRefsValid && variableOptionRef.isValid(), true)
    },
  },
  validations: {
    form: {
      name: { required },
      variableType: { required },
      order: { required },
      pattern: {
        required,
        isPattern: (value) => {
          return value && value.startsWith('$/') && value.endsWith('/')
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/admin_common';

.radio-label {
  top: 0px;
}

.smart-variables-clear-button {
  position: relative;
  top: -10px;
}
</style>

<style lang="scss">
.md-radio-label.md-radio-label {
  position: relative;
  top: 0;
}
</style>

<template>
  <div>
    <finalized-documents :only-recent-documents="true" />
    <new-document-dialog v-if="isNewDocumentDialogOpen" @close="closeDialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewDocumentDialog from './NewDocumentDialog.vue'
import FinalizedDocuments from '../account/finalized_documents/table/FinalizedDocuments.vue'

export default {
  components: {
    NewDocumentDialog,
    FinalizedDocuments,
  },
  props: {
    id: { type: [String, Number], required: true },
  },
  data () {
    return {
      isNewDocumentDialogOpen: true,
    }
  },
  mounted () {
    this.trackPayment(this.id)
  },
  computed: {
    ...mapGetters(['accountSlug']),
  },
  methods: {
    ...mapActions('payments', ['trackPayment']),
    closeDialog () {
      this.isNewDocumentDialogOpen = false
      this.$router.push({ name: 'account_recent_finalized_documents_path', params: { accountId: this.accountSlug } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./common";
@import "../common";
</style>

<template>
  <form novalidate @submit.prevent="submit">
    <div>
      <md-checkbox v-model="isCompany">Registracija kot podjetje</md-checkbox>

      <template v-if="isCompany">
        <md-field :class="getValidationClass('companyName')">
          <label>Ime podjetja</label>
          <md-input v-model="form.companyName" />
          <span class="md-error" v-if="!$v.form.companyName.required">Vpišite ime podjetja</span>
        </md-field>

        <md-field :class="getValidationClass('vatId')">
          <label>Davčna številka</label>
          <md-input v-model="form.vatId" />
          <span class="md-error" v-if="!$v.form.vatId.required">Vpišite davčno številko</span>
        </md-field>
      </template>

      <template v-else>
        <v-field :class="getValidationClass('name')">
          <label>Ime in Priimek</label>
          <v-input v-model="form.name" ref="name" autocomplete="name" />
          <span class="md-error" v-if="!$v.form.name.required">Vpišite ime in priimek ali ime podjetja</span>
        </v-field>
      </template>

      <v-field :class="getValidationClass('email')">
        <label>Email</label>
        <v-input v-model="form.email" type="email" autocomplete="email" @focus="scrollToField" />
        <span class="md-error" v-if="!$v.form.email.required">Vpišite email naslov</span>
        <span class="md-error" v-if="$v.form.email.required && !$v.form.email.email">Neveljaven email naslov</span>
      </v-field>

      <v-field :class="getValidationClass('password')">
        <label>Geslo</label>
        <v-input v-model="form.password" type="password" autocomplete="new-password" />
        <span class="md-error" v-if="$v.form.password && !$v.form.password.required">Vpišite geslo</span>
        <span class="md-error" v-if="$v.form.password && $v.form.password.required && !$v.form.password.minLength">Geslo mora biti dolgo najmanj 6 znakov</span>
      </v-field>

      <v-field :class="getValidationClass('confirmPassword')">
        <label>Ponovite geslo</label>
        <v-input v-model="form.confirmPassword" type="password" autocomplete="new-password" />
        <span class="md-error" v-if="!$v.form.confirmPassword.sameAs">Gesli se ne ujemata</span>
      </v-field>

      <div class="checkbox">
        <v-checkbox class="consent-checkbox" v-model="form.isConsentGiven">Strinjam se s <router-link class="consent-link" :to="{ name: 'privacy_path' }">Politiko zasebnosti</router-link> in <router-link class="consent-link" :to="{ name: 'terms_path' }">Pogoji uporabe</router-link>.</v-checkbox>
        <div class="consent-error" v-if="isSubmitted && $v.form.isConsentGiven.sameAs !== true">Strinjati se morate s Politiko zasebnosti in Pogoji uporabe</div>
      </div>

      <v-button type="submit" :disabled="isDisabled">Registracija</v-button>
    </div>
  </form>
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import FocusableFieldMixin from '../shared/FocusableFieldMixin'
import ValidationMixin from '../shared/ValidationMixin'
import UserFormMixin from '../shared/UserFormMixin'
import VButton from 'design-system/VButton.vue'
import VCheckbox from 'design-system/VCheckbox.vue'
import VField from 'design-system/VField.vue'
import VInput from 'design-system/VInput.vue'

export default {
  components: { VButton, VCheckbox, VField, VInput },
  props: {
    user: { type: Object, default: null },
    isDisabled: { type: Boolean, default: false },
  },
  mounted () {
    this.$refs.name.focus()
  },
  mixins: [FocusableFieldMixin, ValidationMixin, UserFormMixin],
  validations () {
    const validations = {
      form: {
        password: {
          required: required,
          minLength: minLength(6),
        },
        isConsentGiven: {
          sameAs: sameAs(() => true),
        },
      },
    }
    return validations
  },
}
</script>

<style lang="scss" scoped>
@import 'app/assets/stylesheets/colors';
@import '../common';

.checkbox {
  margin-bottom: 28px;
  text-align: left;
}

.consent-link.consent-link {
  text-decoration: underline;

  &:hover {
    color: $secondary_color;
    text-decoration: none;
  }
}

.consent-checkbox {
  /deep/ .md-checkbox-label {
    @include respond-to(small) {
      font-size: 14px;
    }
  }
}

.consent-error {
  color: $error_red;
  font-size: 12px;
}

/deep/ .md-checkbox {
  margin-bottom: 0
}

/deep/ .md-checkbox-label {
  height: fit-content;
}
</style>


import axios from 'axios'
import router from '@/router'

const state = {
  finalizedDocument: null,
  finalizedDocumentId: null,
  stripePublishableApiKey: null,
  braintreeClientToken: null,
  promotionCode: null,
  transactionId: null,
}
const getState = () => JSON.parse(JSON.stringify(state))

export default {
  namespaced: true,
  state: getState(),
  getters: {
    finalizedDocumentPrice (state) {
      if (!state.finalizedDocument) {
        return null
      }
      if (!state.promotionCode) {
        return state.finalizedDocument.price
      }

      return Math.max(0.5, state.finalizedDocument.price - state.promotionCode.value).toFixed(2)
    },
    originalPrice (state) {
      if (!state.finalizedDocument) {
        return null
      }

      return state.finalizedDocument.price
    },
    isPriceReduced (_, getters) {
      return getters.originalPrice !== getters.finalizedDocumentPrice
    },
    isStripeButtonReady (state) {
      return !!state.stripePublishableApiKey
    },
    promotionCodeCode (state) {
      if (!state.promotionCode) {
        return null
      }

      return state.promotionCode.code
    },
    vatAmount (_, getters) {
      if (!getters.originalPrice) {
        return null
      }

      return (getters.originalPrice * 0.22).toFixed(2)
    },
    reducedPriceDifference (_, getters) {
      if (!getters.isPriceReduced) {
        return null
      }

      return (getters.originalPrice - getters.finalizedDocumentPrice).toFixed(2)
    },
  },
  actions: {
    initialize ({ commit, dispatch }, finalizedDocumentId) {
      commit('RESET_STATE')
      if (!finalizedDocumentId) {
        throw new Error('Finalized document id not present!')
      }

      commit('SET_IS_LOADING', true, { root: true })
      commit('SET_FINALIZED_DOCUMENT_ID', finalizedDocumentId)

      const fetchStripePublishableApiKey = axios
        .get('/stripe/publishable_api_key')
        .then(response => commit('SET_STRIPE_PUBLISHABLE_API_KEY', response.data.publishable_api_key))
        .catch(error => console.error(error))
      const fetchBraintreeClientToken = axios
        .get('/braintree/client_token')
        .then(response => commit('SET_BRAINTREE_CLIENT_TOKEN', response.data.client_token))
        .catch(error => console.error(error))

      return Promise.all([
        dispatch('initializeFinalizedDocument', finalizedDocumentId),
        fetchStripePublishableApiKey,
        fetchBraintreeClientToken,
      ])
        .then(() => setTimeout(dispatch('trackInitEvents'), 0))
        .finally(() => commit('SET_IS_LOADING', false, { root: true }))
    },
    initializeFinalizedDocument ({ state, commit }) {
      if (!state.finalizedDocumentId) {
        throw new Error('Finalized document id not present!')
      }

      return axios
        .get(`/finalized_documents/${state.finalizedDocumentId}`)
        .then(response => commit('SET_FINALIZED_DOCUMENT', response.data))
        .catch(error => handleError(error, state.finalizedDocumentId, 'Dokumenta s tem identifikatorjem ni bilo mogoče pridobiti, prosimo preverite URL naslov.'))
    },
    initializeBraintree ({ state, getters, commit, dispatch }, payPalButtonSelector) {
      if (!state.braintreeClientToken || typeof braintree === 'undefined') {
        return
      }

      return braintree.client // eslint-disable-line no-undef
        .create({ authorization: state.braintreeClientToken })
        .then(clientInstance => braintree.paypalCheckout.create({ client: clientInstance })) // eslint-disable-line no-undef
        .then(paypalCheckoutInstance => {
          return paypalCheckoutInstance.loadPayPalSDK({
            currency: 'EUR',
            intent: 'capture',
          })
        }).then(paypalCheckoutInstance => {
          commit('GENERATE_TRANSACTION_ID')
          return paypal.Buttons({ // eslint-disable-line no-undef
            fundingSource: paypal.FUNDING.PAYPAL, // eslint-disable-line no-undef
            createOrder: function () {
              return paypalCheckoutInstance.createPayment({
                flow: 'checkout',
                amount: getters.finalizedDocumentPrice,
                currency: 'EUR',
                intent: 'capture',
              })
            },
            onApprove: (data) => {
              commit('SET_IS_LOADING', true, { root: true })
              paypalCheckoutInstance.tokenizePayment(data).then((payload) => dispatch('braintreeCheckout', payload.nonce))
            },
            onCancel: () => router.push({ name: 'finalized_document_payment_failed_path', params: { id: state.finalizedDocumentId } }),
            onError: (error) => console.error('PayPal error', error),
          }).render(payPalButtonSelector)
        }).catch(error => {
          if (state.finalizedDocument) {
            handleError(error, state.finalizedDocumentId)
          }
        })
    },
    setPromotionCode ({ state, commit }, promotionCode) {
      if (!state.finalizedDocument) {
        return
      }

      if (!promotionCode) {
        commit('SET_PROMOTION_CODE', null)
        return
      }

      return axios
        .get(`/promotion_codes?by_code=${promotionCode}`)
        .then(response => {
          if (response && response.data.length !== 1) {
            commit('SET_MESSAGE', { text: 'Neveljavna promocijska koda.' , type: 'error' }, { root: true })
            return
          }
          if (response.data[0].is_valid !== true) {
            commit('SET_MESSAGE', { text: 'Promocijska koda je potekla.', type: 'error' }, { root: true })
            return
          }

          commit('SET_PROMOTION_CODE', response.data[0])
        })
        .catch(error => {
          console.error(error)
          commit('SET_MESSAGE', { text: 'Napaka pri preverjanju promocijske kode.', type: 'error' }, { root: true })
        })
    },
    stripeCheckout ({ state, getters, commit }) {
      if (!state.stripePublishableApiKey) {
        throw new Error('Stripe API keys not configured!')
      }

      commit('GENERATE_TRANSACTION_ID')
      const stripe = Stripe(state.stripePublishableApiKey) // eslint-disable-line no-undef
      const payload = {
        payment_gateway: 'stripe',
        finalized_document_id: state.finalizedDocumentId,
        promotion_code: getters.promotionCodeCode,
      }

      return axios
        .post('/create_checkout_session', payload)
        .then(response => response.data)
        .then(session => stripe.redirectToCheckout({ sessionId: session.id }))
        .then(result => {
          if (result.error) {
            handleError(result.error, state.finalizedDocumentId)
          }
        })
        .catch(error => handleError(error, state.finalizedDocumentId))
    },
    braintreeCheckout ({ state, getters }, nonce) {
      if (!state.braintreeClientToken) {
        handleError('Braintree client token not present!', state.finalizedDocumentId)
        return
      }
      if (typeof braintree === 'undefined') {
        handleError('Braintree global object not present!', state.finalizedDocumentId)
        return
      }

      return braintree.client // eslint-disable-line no-undef
        .create({ authorization: state.braintreeClientToken })
        .then(clientInstance => braintree.dataCollector.create({ client: clientInstance, paypal: true })) // eslint-disable-line no-undef
        .then(dataCollectorInstance => {
          const payload = {
            payment_gateway: 'braintree',
            finalized_document_id: state.finalizedDocumentId,
            payment_method_nonce: nonce,
            device_data: dataCollectorInstance.deviceData,
            promotion_code: getters.promotionCodeCode,
          }

          return axios
            .post('/create_checkout_session', payload)
            .then(result => {
              if (result.data.status === 'paid') {
                router.push({ name: 'finalized_document_paid_path', params: { id: state.finalizedDocumentId } })
              } else {
                router
                  .push({ name: 'finalized_document_payment_failed_path', params: { id: state.finalizedDocumentId } })
                  .catch(() => router.go())
              }
            })
            .catch(error => handleError(error, state.finalizedDocumentId))
        })
        .catch(error => handleError(error, state.finalizedDocumentId))
    },
    reset ({ commit }) {
      commit('RESET_STATE')
    },
    trackInitEvents ({ state, dispatch }) {
      if (!state.finalizedDocument) {
        // eslint-disable-next-line no-console
        console.warn('Finalized document not present!')
        return
      }

      const items = [
        {
          item_category: 'Vsi vzorci',
          item_list_id: 'vzorci',
          item_list_name: 'Vzorci',
          item_id: state.finalizedDocument.id,
          item_name: state.finalizedDocument.name,
          price: state.finalizedDocument.price,
          quantity: 1,
          index: 1,
        },
      ]

      dispatch('trackEvents', [
        {
          name: 'conversion',
          data: {
            send_to: 'AW-16663620351/9L1vCIuLvsgZEP_V6ok-',
            value: state.finalizedDocument.price,
            currency: 'EUR',
          },
        },
        {
          name: 'begin_checkout',
          data: {
            send_to: 'G-5JHZXZD27X',
            value: state.finalizedDocument.price,
            currency: 'EUR',
            items,
          },
        },
        {
          name: 'add_shipping_info',
          data: {
            send_to: 'G-5JHZXZD27X',
            shipping_tier: 'Online',
            value: state.finalizedDocument.price,
            currency: 'EUR',
            items,
          },
        },
      ], { root: true })
    },
    trackPaymentEvents ({ getters, dispatch }, paymentGateway) {
      dispatch('trackEvents', [
        {
          name: 'add_payment_info',
          data: {
            send_to: 'G-5JHZXZD27X',
            value: getters.finalizedDocumentPrice,
            currency: 'EUR',
            coupon: getters.promotionCodeCode ?? '',
            payment_type: paymentGateway,
          },
        },
      ], { root: true })
    },
    async trackPayment ({ state, getters, rootState, commit, dispatch }, finalizedDocumentId) {
      commit('SET_FINALIZED_DOCUMENT_ID', finalizedDocumentId)
      commit('GENERATE_TRANSACTION_ID')
      await dispatch('initializeFinalizedDocument')
      dispatch('trackEvents', [
        {
          name: 'conversion',
          data: {
            send_to: 'AW-16663620351/5QhCCIWLvsgZEP_V6ok-',
            value: getters.finalizedDocumentPrice,
            transaction_id: state.transactionId,
            new_customer: rootState.user.isNewCustomer,
          },
        },
        {
          name: 'purchase',
          data: {
            send_to: 'AW-16663620351',
            value: getters.finalizedDocumentPrice,
            dynx_pagetype: 'conversion',
            dynx_totalvalue: getters.finalizedDocumentPrice,
            dynx_itemid: [state.finalizedDocument.id],
            currency: 'EUR',
            items: [
              {
                id: state.finalizedDocument.id,
                price: getters.finalizedDocumentPrice,
                google_business_vertical: 'custom',
              },
            ],
          },
        },
        {
          name: 'purchase',
          data: {
            send_to: 'G-5JHZXZD27X',
            transaction_id: state.transactionId,
            value: getters.finalizedDocumentPrice,
            tax: getters.vatAmount,
            currency: 'EUR',
            coupon: getters.promotionCodeCode ?? '',
            items: [
              {
                item_category: 'Vsi vzorci',
                item_list_id: 'vzorci',
                item_list_name: 'Vzorci',
                item_id: state.finalizedDocument.id,
                item_name: state.finalizedDocument.name,
                price: getters.finalizedDocumentPrice,
                quantity: 1,
                index: 1,
              },
            ],
          },
        },
      ], { root: true })
    },
  },
  mutations: {
    SET_FINALIZED_DOCUMENT_ID (state, finalizedDocumentId) {
      state.finalizedDocumentId = finalizedDocumentId
    },
    SET_FINALIZED_DOCUMENT (state, finalizedDocument) {
      state.finalizedDocument = finalizedDocument
    },
    SET_PROMOTION_CODE (state, promotionCode) {
      state.promotionCode = promotionCode
    },
    SET_STRIPE_PUBLISHABLE_API_KEY (state, stripePublishableApiKey) {
      state.stripePublishableApiKey = stripePublishableApiKey
    },
    SET_BRAINTREE_CLIENT_TOKEN (state, braintreeClientToken) {
      state.braintreeClientToken = braintreeClientToken
    },
    GENERATE_TRANSACTION_ID (state) {
      state.transactionId = Math.random().toString(36).substring(7)
    },
    RESET_STATE (state) {
      Object.assign(state, getState())
    },
  },
}

function handleError (error, finalizedDocumentId, message = null) {
  console.error(error)
  router.push({ name: 'finalized_document_payment_failed_path', params: { id: finalizedDocumentId, error: message } }).catch(() => router.go())
}

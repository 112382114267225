import SmartContracts from '@/components/pages/SmartContracts.vue'
import AccountPanelUsers from '@/components/account/users/Users.vue'
import AccountPanelNewUser from '@/components/account/users/NewUser.vue'
import AccountPanelEditUser from '@/components/account/users/EditUser.vue'
import AccountPanelAttributeKeys from '@/components/account/attribute_keys/AttributeKeys.vue'
import AccountPanelNewAttributeKey from '@/components/account/attribute_keys/NewAttributeKey.vue'
import AccountPanelEditAttributeKey from '@/components/account/attribute_keys/EditAttributeKey.vue'
import AccountPanelFinalizedDocuments from '@/components/account/finalized_documents/table/FinalizedDocuments.vue'
import AccountPanelNewFinalizedDocument from '@/components/account/finalized_documents/NewFinalizedDocument.vue'
import AccountPanelEditFinalizedDocument from '@/components/account/finalized_documents/EditFinalizedDocument.vue'
import AccountPanelNewEnvelope from '@/components/account/finalized_documents/NewEnvelope.vue'
import AccountPanelEditEnvelope from '@/components/account/finalized_documents/EditEnvelope.vue'
import AccountPanelESignFinalizedDocument from '@/components/account/e_sign/ESignFinalizedDocument.vue'

const accountAdminRoutes = [
  { path: '/racun/:accountId', component: SmartContracts, name: 'account_root_path', meta: {} },
  { path: '/racun/:accountId/uporabniki', component: AccountPanelUsers, name: 'account_users_path', meta: { title: 'Računi' }, props: true },
  { path: '/racun/:accountId/uporabniki/new', component: AccountPanelNewUser, name: 'account_user_create_path', meta: { title: 'Nov Račun' }, props: true },
  { path: '/racun/:accountId/uporabniki/:id', component: AccountPanelEditUser, name: 'account_user_edit_path', meta: { title: 'Uredi Račun' }, props: true },
  { path: '/racun/:accountId/taksonomija', component: AccountPanelAttributeKeys, name: 'account_attribute_keys_path', meta: { title: 'Oznake dokumentov' }, props: true },
  { path: '/racun/:accountId/taksonomija/new', component: AccountPanelNewAttributeKey, name: 'account_attribute_key_create_path', meta: { title: 'Nova Oznaka' }, props: true },
  { path: '/racun/:accountId/taksonomija/:id', component: AccountPanelEditAttributeKey, name: 'account_attribute_key_edit_path', meta: { title: 'Uredi Oznako' }, props: true },
]

const accountRoutes = [
  {
    path: '/racun/:accountId/dokumenti',
    component: AccountPanelFinalizedDocuments,
    name: 'account_finalized_documents_path',
    meta: { title: 'Hramba' },
    props: route => ({ wasDocumentJustESigned: route.query.podpisano === '1', finalizedDocumentId: route.query.id?.toString() }),
  },
  {
    path: '/racun/:accountId/zadnje-ustvarjeni',
    component: AccountPanelFinalizedDocuments,
    name: 'account_recent_finalized_documents_path',
    meta: { title: 'Zadnje ustvarjeni' },
    props: { onlyRecentDocuments: true },
  },
  { path: '/racun/:accountId/dokumenti/new', component: AccountPanelNewFinalizedDocument, name: 'account_finalized_document_create_path', meta: { title: 'Nov dokument' }, props: true },
  { path: '/racun/:accountId/dokumenti/:id/edit', component: AccountPanelEditFinalizedDocument, name: 'account_finalized_document_edit_path', meta: { title: 'Uredi dokument' }, props: true },
  { path: '/racun/:accountId/mape/new', component: AccountPanelNewEnvelope, name: 'account_envelope_create_path', meta: { title: 'Nova Mapa' }, props: true },
  { path: '/racun/:accountId/mape/:id', component: AccountPanelEditEnvelope, name: 'account_envelope_edit_path', meta: { title: 'Uredi Mapo' }, props: true },
  { path: '/racun/:accountId/dokumenti/:id/sign', component: AccountPanelESignFinalizedDocument , name: 'account_finalized_document_e_signature_path', meta: { title: 'Pošlji dokument v e-podpis' }, props: true },
]

export default accountAdminRoutes.map(route => {
  route.meta.isAccountRoute = true
  route.meta.isLoginRequired = true
  route.meta.isAccountAdminRequired = true
  return route
}).concat(accountRoutes.map(route => {
  route.meta.isAccountRoute = true
  route.meta.isLoginRequired = true
  return route
}))

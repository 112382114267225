import { required, requiredIf, email, sameAs } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        email: null,
        currentPassword: null,
        password: null,
        confirmPassword: null,
        firstName: null,
        lastName: null,
        companyName: null,
        vatId: null,
        address: null,
        postAndPostCode: null,
        isConsentGiven: false,
        name: null,
      },
      isCompany: false,
      isSubmitted: false,
      isEditPage: false,
    }
  },
  created () {
    if (!this.user) {
      return
    }
    this.form.email = this.user.email
    this.form.firstName = this.user.firstName
    this.form.lastName = this.user.lastName
    this.form.companyName = this.user.companyName
    this.form.vatId = this.user.vatId
    this.form.address = this.user.address
    this.form.postAndPostCode = this.user.postAndPostCode
    this.isCompany = !!(this.user.companyName || this.user.vatId)
  },
  methods: {
    submit () {
      this.isSubmitted = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.$emit('submit', this.registerData)
    },
  },
  computed: {
    registerData () {
      const userData = {
        email: this.form.email,
        currentPassword: this.form.currentPassword,
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        firstName: null,
        lastName: null,
        companyName: null,
        vatId: null,
        postAndPostCode: null,
      }
      if (this.isEditPage) {
        if (this.isCompany) {
          userData.companyName = this.form.companyName
          userData.vatId = this.form.vatId
          userData.address = this.form.address
          userData.postAndPostCode = this.form.postAndPostCode
        } else {
          userData.firstName = this.form.firstName
          userData.lastName = this.form.lastName
        }
      } else {
        if (this.isCompany) {
          userData.companyName = this.form.companyName
          userData.vatId = this.form.vatId
        } else {
          const name = this.form.name.split(' ')
          userData.firstName = name[0]
          userData.lastName = name.slice(1).join(' ')
        }
      }
      return userData
    },
  },
  validations () {
    return {
      form: {
        email: {
          required,
          email,
        },
        confirmPassword: {
          sameAs: sameAs('password'),
        },
        companyName: {
          required: requiredIf(() => this.isCompany),
        },
        vatId: {
          required: requiredIf(() => this.isCompany),
        },
        name: {
          required: requiredIf(() => !this.isCompany && !this.isEditPage),
        },
      },
    }
  },
}


<template>
  <div class="page-container">
    <div class="e-signature__header">
      <div class="e-signature__header__title">
        <icon name="arrow-left" @click="goToFinalizedDocument" />
        <h1 class="page-title">Pošlji dokument v E-podpis</h1>
      </div>
    </div>
    <template v-if="!isLoading">
      <div class="page-subtitle-badge" v-if="finalizedDocument">
        {{ finalizedDocument.name }} {{ finalizedDocument.description && ` - ${finalizedDocument.description}` }}
      </div>
      <div class="e-signature__content">
        <div class="e-signature__content__container">
          <e-signature-waiting-signature v-if="isSentForSignature" :signers="signers" :signed-by="finalizedDocument.signedBy" @go-to-storage="goToFinalizedDocument" @re-sign="cancelESigning(id)" />
          <div v-else-if="isESigned">Dokument je že podpisan.</div>
          <e-signature-signers-form v-else :signers="form.signers" @change="onSignersFormUpdate" @sign="signDocument" ref="signersForm" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import BackMixin from '@/components/shared/BackMixin'
import ValidationMixin from '@/components/shared/ValidationMixin'
import Icon from 'design-system/icons/Icon.vue'
import ESignatureWaitingSignature from './ESignatureWaitingSignature.vue'
import ESignatureSignersForm from './ESignatureSignersForm.vue'

export default {
  components: {
    Icon,
    ESignatureWaitingSignature,
    ESignatureSignersForm,
  },
  mixins: [BackMixin, ValidationMixin],
  props: {
    id: { type: [String, Number], required: true },
  },
  data () {
    return {
      wasJustSentForSignature: false,
      isFormValid: true,
      form: {
        signers: [{ name: '', email: '' }],
      },
    }
  },
  mounted () {
    this.initialize(this.id).then(() => {
      if (this.finalizedDocument?.signers) {
        this.form.signers = this.finalizedDocument.signers
      }
    })
  },
  computed: {
    ...mapState(['user', 'isLoading']),
    ...mapState('eSign', ['finalizedDocument']),
    ...mapGetters(['accountSlug']),
    isESigned () {
      if (!this.finalizedDocument) {
        return false
      }

      return this.finalizedDocument.isESigned
    },
    isSentForSignature () {
      return this.finalizedDocument?.isWaitingESignature || this.wasJustSentForSignature
    },
    signers () {
      return this.finalizedDocument?.signers || this.form.signers
    },
  },
  methods: {
    ...mapActions('eSign', ['initialize', 'eSignDocument', 'cancelESigning']),
    signDocument () {
      this.runAllValidations()
      if (!this.isValid()) {
        return
      }

      this.eSignDocument({
        signers: this.form.signers,
        finalizedDocumentId: this.finalizedDocument.id,
      }).then(() => (this.wasJustSentForSignature = true))
    },
    onSignersFormUpdate (form) {
      this.form.signers = form.signers
      this.isValid()
    },
    goToRecentFinalizedDocuments () {
      this.$router.push({
        name: 'account_recent_finalized_documents_path',
        params: {
          accountId: this.accountSlug,
        },
      })
    },
    goToFinalizedDocument () {
      this.$router.push({
        name: 'account_finalized_documents_path',
        params: {
          accountId: this.accountSlug,
        },
        query: {
          id: this.finalizedDocument.id,
        },
      }).catch(() => this.$router.go())
    },
    runAllValidations () {
      this.$refs.signersForm.runAllValidations()
    },
    isValid () {
      this.isFormValid = this.$refs.signersForm?.isValid()
      return this.isFormValid
    },
  },
}
</script>

<style lang="scss" scoped>
@import "app/assets/stylesheets/variables";

.e-signature__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @include respond-to(small) {
    flex-direction: column;
  }
}

.e-signature__header__title {
  display: flex;
  align-items: center;

  @include respond-to(small) {
    margin-bottom: 16px;
  }

  & .page-title {
    margin-bottom: 0;
  }

  & .icon-wrapper {
    display: flex;
    align-items: center;
    margin: 20px 16px 0 0;
    cursor: pointer;
  }
}

.e-signature__content {
  padding: 32px 48px;
  border-radius: 6px;
  background: $white;
  color: $black;
  font-size: 18px;
  font-weight: 500;

  &__container {
    max-width: 722px;
  }
}
</style>
